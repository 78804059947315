/* eslint-disable no-console */
import type { Socket, ManagerOptions, SocketOptions } from 'socket.io-client';
import io from 'socket.io-client';
import store from 'src/store/store';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import { type ISocketBaseControllersType } from './SocketBaseControllers';
import { SocketEventsENUM } from './socketEvents';
import refreshToken from '../http/refreshToken';

class BaseSocket {
  readonly socket: Socket;

  constructor(
    private connectParams: { uri: string } & Partial<ManagerOptions & SocketOptions>,
    private baseControllers: ISocketBaseControllersType,
  ) {
    this.socket = io(this.connectParams.uri, {
      transports: ['websocket'],
      autoConnect: false,
      auth: {},
      ...this.connectParams,
    });
  }

  private addBaseListeners() {
    this.socket.on(SocketEventsENUM.connect, () => this.baseControllers.onConnect(this.socket));
    this.socket.on(SocketEventsENUM.error, (err) => this.baseControllers.onError(err));

    this.socket.on(SocketEventsENUM.connectError, (err) => {
      return this.baseControllers.onConnectError(err);
    });

    this.socket.on(SocketEventsENUM.customError, this.handleCustomError);
    this.socket.on(SocketEventsENUM.customError, (err) => this.baseControllers.onError(err));
    this.socket.on(SocketEventsENUM.disconnect, this.baseControllers.onDisconnect);
    this.socket.on(SocketEventsENUM.forceLogout, () => {
      this.baseControllers.handleForceLogout();
      this.disconnect();
    });
  }

  async connect(options?: {
    /** `false` by default */
    shouldThrowError?: boolean;
  }) {
    if (this.socket.connected) {
      console.error('socket.connect was called with already connected socket');
      return;
    }
    this.socket.auth = this.baseControllers.getAuth();

    await new Promise<void>((res, rej) => {
      this.socket.removeAllListeners();
      this.addBaseListeners();

      const unsubscribe = () => {
        this.socket.off(SocketEventsENUM.connect, handleConnect);
        this.socket.off(SocketEventsENUM.error, handleError);
        this.socket.off(SocketEventsENUM.disconnect, handleDisconnect);
      };

      const handleConnect = () => {
        unsubscribe();
        // eslint-disable-next-line no-console
        console.info('Socket is connected');
        store.dispatch(mainSliceActions.setConnectionStatus(true));
        res();
      };

      const handleError = (err: Error) => {
        unsubscribe();
        store.dispatch(mainSliceActions.setConnectionStatus(false));
        rej(err);
      };

      const handleDisconnect = () => {
        unsubscribe();
        store.dispatch(mainSliceActions.setConnectionStatus(false));
        rej(new Error('Socket was disconnected'));
      };

      this.socket.on(SocketEventsENUM.connect, handleConnect);
      this.socket.on(SocketEventsENUM.error, handleError);
      this.socket.on(SocketEventsENUM.disconnect, handleDisconnect);

      this.socket.connect();
    }).catch((err) => {
      store.dispatch(mainSliceActions.setConnectionStatus(false));
      if (options?.shouldThrowError) {
        throw err;
      }
    });
  }

  async disconnect(options?: {
    /** `false` by default */
    shouldThrowError?: boolean;
  }) {
    if (this.socket.disconnected) {
      console.error('socket.disconnect was called without the connected socket');
      return;
    }

    await new Promise<void>((res, rej) => {
      const unsubscribe = () => {
        this.socket.off(SocketEventsENUM.error, handleError);
        this.socket.off(SocketEventsENUM.disconnect, handleDisconnect);
      };

      const handleError = (err: Error) => {
        unsubscribe();
        store.dispatch(mainSliceActions.setConnectionStatus(false));
        rej(err);
      };

      const handleDisconnect = () => {
        unsubscribe();
        console.info('Socket is disconnected');
        store.dispatch(mainSliceActions.setConnectionStatus(false));
        res();
      };

      this.socket.on(SocketEventsENUM.error, handleError);
      this.socket.on(SocketEventsENUM.disconnect, handleDisconnect);

      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.addBaseListeners();
      store.dispatch(mainSliceActions.setConnectionStatus(false));
    }).catch((err) => {
      store.dispatch(mainSliceActions.setConnectionStatus(false));
      if (options?.shouldThrowError) {
        throw err;
      }
    });
  }

  private async handleCustomError(err: { message: string; payload: [string, unknown] }) {
    if (err?.message === 'Token expired') {
      const isSuccess = await this.refreshToken();

      if (!isSuccess) {
        console.error('Socket connection error:', err);
        return;
      }

      this.socket.emit(err.payload[0], err.payload[1]);

      return;
    }

    console.error('Custom socket error:', err);
  }

  public waitForConnection() {
    return new Promise<void>((res, rej) => {
      if (this.socket.connected) {
        res();
        return;
      }

      const handleConnection = () => {
        unsubscribe();
        res();
      };
      const handleError = (err: Error) => {
        unsubscribe();
        rej(err);
      };
      const unsubscribe = () => {
        this.socket.off(SocketEventsENUM.connect, handleConnection);
        this.socket.off(SocketEventsENUM.error, handleError);
      };

      this.socket.on(SocketEventsENUM.connect, handleConnection);
      this.socket.on(SocketEventsENUM.error, handleError);
    });
  }

  private async refreshToken() {
    const { isRefreshed } = await refreshToken();

    if (!isRefreshed) {
      await this.disconnect();
      return false;
    }

    await this.disconnect();
    const isConnected = await this.connect({ shouldThrowError: true })
      .then(() => true)
      .catch(() => false);

    return isConnected;
  }
}

export default BaseSocket;

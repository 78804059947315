import type { EntityState } from '@reduxjs/toolkit';
import type { FindAndCountMetaType, IAdmin } from 'src/types';
import { createSlice } from '@reduxjs/toolkit';

import { adminsEntityAdapter } from './entityAdapter';
import { adminsThunks } from './thunks';

type AdminsStateType = {
  admins: EntityState<IAdmin>;
  pagination: FindAndCountMetaType | null;
  currentPageItems: number[];
  refreshDataTrigger: boolean;
};

const initialState: AdminsStateType = {
  admins: adminsEntityAdapter.getInitialState(),
  pagination: null,
  currentPageItems: [],
  refreshDataTrigger: false,
};

const adminsSlice = createSlice({
  name: 'moderators',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminsThunks.getAdminsListThunk.fulfilled, (store, action) => {
      adminsEntityAdapter.setMany(store.admins, action.payload.users);
      store.pagination = action.payload.pagination;
      store.currentPageItems = action.payload.users.map(({ adminId }) => adminId);
    });

    builder.addCase(adminsThunks.getAdminByIdThunk.fulfilled, (store, action) => {
      if (!action.payload) {
        return;
      }
      adminsEntityAdapter.setOne(store.admins, action.payload);
    });

    builder.addCase(adminsThunks.createAdminThunk.fulfilled, (store) => {
      store.refreshDataTrigger = !store.refreshDataTrigger;
    });

    builder.addCase(adminsThunks.updateAdminRoleThunk.fulfilled, (store, action) => {
      adminsEntityAdapter.setOne(store.admins, action.payload);
    });

    builder.addCase(adminsThunks.deleteAdminById.fulfilled, (store, action) => {
      const removeId = action.meta.arg.adminId;
      adminsEntityAdapter.removeOne(store.admins, removeId);
      store.currentPageItems = store.currentPageItems.filter((id) => removeId !== id);
    });
  },
});

export const moderatorsActions = adminsSlice.actions;
export default adminsSlice.reducer;

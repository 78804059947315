import axios from 'axios';
import type { AxiosError } from 'axios';
import type { useForm } from 'react-hook-form';
import ShowErrorToast from './showToast/ShowErrorToast';

type HandledErrorCodesType = 404 | 400 | 403 | 409 | 413 | 401 | 500;

export const checkIsAxiosError = (err: Error | unknown): err is AxiosError => {
  return axios.isAxiosError(err);
};

export const checkIsValidationError = (err: Error | unknown): err is AxiosError => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 400;
};

export const checkIsForbiddenError = (err: Error | unknown): err is AxiosError => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 403;
};

export const checkIsNotFoundError = (err: Error | unknown): err is AxiosError => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 404;
};
export const checkIsConflictError = (err: Error | unknown): err is AxiosError => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 409;
};

export const checkIsContentTooLargeError = (err: Error | unknown): err is AxiosError => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 413;
};

export const checkIsUnauthorizedError = (err: Error | unknown): err is AxiosError => {
  if (!checkIsAxiosError(err)) {
    return false;
  }

  return err.response?.status === 401;
};

export const showErrorMessageByCode = <E = unknown>(
  error: E, messages: { [key in HandledErrorCodesType]?: string },
) => {
  if (!checkIsAxiosError(error)) {
    return;
  }
  if (!error.response?.status) {
    return;
  }
  const message = messages[error.response.status as HandledErrorCodesType];
  if (!message) {
    return;
  }
  ShowErrorToast(message);
};

type ValidationErrorType = {
  code: 'server.validation.failed';
  data: Array<{
    path: string;
    key: string;
    code: string;
    message: string;
  }>;
  message: 'Invalid request data';
  statusCode: 400;
  type: 'validation';
};

const handleValidationError = <V extends object>(
  err: Error | unknown,
  setFieldError: ReturnType<typeof useForm<V>>['setError'],
) => {
  (err as AxiosError<ValidationErrorType>).response?.data.data.forEach(
    (error) => {
      setFieldError(`root.${error.key}`, { type: error.code });
    },
  );
};

export default handleValidationError;

import type { IKYCVerification, IUser } from '../types';
import { PathENUM } from './constans';
import http from './http';

const acceptRequest = (kycVerificationId: number, options?: {
  abortSignal?: AbortSignal;
}) => {
  return http.patch<IUser>(
    `${PathENUM.KYC}${PathENUM.ACCEPT}/${kycVerificationId}`,
    {},
    { signal: options?.abortSignal },
  );
};

const rejectRequest = (kycVerificationId?: number, comment?: string, options?: {
  abortSignal?: AbortSignal;
}) => {
  return http.patch<IKYCVerification>(
    `${PathENUM.KYC}${PathENUM.REJECT}/${kycVerificationId}`, { comment }, { signal: options?.abortSignal },
  );
};

const getUserRequestById = (userId?: number, options?: {
  abortSignal?: AbortSignal;
}) => {
  return http.get<IKYCVerification[]>(`${PathENUM.KYC}/${userId}`, {
    signal: options?.abortSignal,
  });
};

const getRequestById = (kycVerificationId?: number, options?: {
  abortSignal?: AbortSignal;
}) => {
  return http.get<IKYCVerification>(
    `${PathENUM.KYC}${PathENUM.REQUEST}/${kycVerificationId}`,
    {
      signal: options?.abortSignal,
    },
  );
};

export default {
  acceptRequest,
  rejectRequest,
  getUserRequestById,
  getRequestById,
};

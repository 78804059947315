import { combineReducers } from 'redux';

import mainSlice from 'src/store/mainSlice/mainSlice.reducer';
import userSlice from 'src/store/users/slice';
import offersSlice from 'src/store/offers/slice';
import adminsSlice from 'src/store/admins/slice';
import chatSlice from 'src/store/chat/slice';

const combinedReducer = combineReducers({
  main: mainSlice,
  usersState: userSlice,
  offersState: offersSlice,
  adminsState: adminsSlice,
  supportChat: chatSlice,
});

export const RESET_STORE_ACTION_TYPE = 'RESET_WHOLE_STORE';

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: { type: string; payload: unknown },
) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;

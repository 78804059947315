import type {
  IBaseMessage,
  IChat,
  IHelpChat,
  IHelpReport,
  IParticipant,
  IParticipantAdmin,
  IReport,
  ISupportChat,
  ISupportReport,
  ITempMessage,
} from 'src/types';
import { ParticipantRoleENUM, ReportTypeENUM } from 'src/types';
import type { IMedia, ITempMedia } from 'src/types/mediaItemType';

export const isParticipantAdmin = (participant: IParticipant): participant is IParticipantAdmin => {
  return participant.participantType === ParticipantRoleENUM.ADMIN;
};

export const isSupportReport = (report: IReport): report is ISupportReport => {
  return report.type === ReportTypeENUM.SUPPORT;
};

export const isHelpReport = (report: IReport): report is IHelpReport => {
  return report.type === ReportTypeENUM.REPORT;
};

export const isSupportChat = (chat: IChat): chat is ISupportChat => {
  return isSupportReport(chat.report);
};

export const isHelpChat = (chat: IChat): chat is IHelpChat => {
  return isHelpReport(chat.report);
};

export const isTempMessage = (message: IBaseMessage): message is ITempMessage => {
  return typeof message.messageId === 'string';
};

export const isTempMedia = (media: ITempMedia | IMedia): media is ITempMedia => {
  return (media as IMedia).mediaItemId === undefined;
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Theme_size_gpnDefault {
  /* Размер текста */
  --size-text-2xs: 12px;
  --size-text-xs: 13px;
  --size-text-s: 14px;
  --size-text-m: 16px;
  --size-text-l: 18px;
  --size-text-xl: 20px;
  --size-text-2xl: 24px;
  --size-text-3xl: 30px;
  --size-text-4xl: 48px;
  --size-text-5xl: 72px;
  --size-text-6xl: 96px;

  /* Межстрочный интервал */
  --line-height-text-2xs: 14px;
  --line-height-text-xs: 16px;
  --line-height-text-s: 18px;
  --line-height-text-m: 21px;
  --line-height-text-l: 24px;

  /* Ширина шрифта */
  --font-weight-text-thin: 100;
  --font-weight-text-extralight: 100;
  --font-weight-text-light: 300;
  --font-weight-text-regular: normal;
  --font-weight-text-medium: 500;
  --font-weight-text-semibold: 600;
  --font-weight-text-bold: bold;
  --font-weight-text-extrabold: 800;
  --font-weight-text-black: 900;

  --input-font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/ui/defaultPreset/_size/Theme_size_gpnDefault.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;;EAErB,yBAAyB;EACzB,4BAA4B;EAC5B,2BAA2B;EAC3B,0BAA0B;EAC1B,0BAA0B;EAC1B,0BAA0B;;EAE1B,kBAAkB;EAClB,4BAA4B;EAC5B,kCAAkC;EAClC,6BAA6B;EAC7B,kCAAkC;EAClC,8BAA8B;EAC9B,gCAAgC;EAChC,6BAA6B;EAC7B,iCAAiC;EACjC,6BAA6B;;EAE7B,uBAAuB;AACzB","sourcesContent":[".Theme_size_gpnDefault {\n  /* Размер текста */\n  --size-text-2xs: 12px;\n  --size-text-xs: 13px;\n  --size-text-s: 14px;\n  --size-text-m: 16px;\n  --size-text-l: 18px;\n  --size-text-xl: 20px;\n  --size-text-2xl: 24px;\n  --size-text-3xl: 30px;\n  --size-text-4xl: 48px;\n  --size-text-5xl: 72px;\n  --size-text-6xl: 96px;\n\n  /* Межстрочный интервал */\n  --line-height-text-2xs: 14px;\n  --line-height-text-xs: 16px;\n  --line-height-text-s: 18px;\n  --line-height-text-m: 21px;\n  --line-height-text-l: 24px;\n\n  /* Ширина шрифта */\n  --font-weight-text-thin: 100;\n  --font-weight-text-extralight: 100;\n  --font-weight-text-light: 300;\n  --font-weight-text-regular: normal;\n  --font-weight-text-medium: 500;\n  --font-weight-text-semibold: 600;\n  --font-weight-text-bold: bold;\n  --font-weight-text-extrabold: 800;\n  --font-weight-text-black: 900;\n\n  --input-font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../store/store';
import { routerConstants } from './constants';

import type { FinalPropsType } from './types';

const Protector: React.FC<FinalPropsType> = (props) => {
  const admin = useAppSelector(({ main }) => main.admin);

  if (props.protectType === 'unprotected' && !admin) {
    return props.children;
  }

  if (props.protectType === 'protected' && admin) {
    if (!props.permission) {
      return props.children;
    }

    if (props.checkPermission?.(admin.role, props.permission)) {
      return props.children;
    }
    return <Navigate to={routerConstants.permissionsNavigate} />;
  }

  return <Navigate to={props.redirectTo[props.protectType] ?? '/'} />;
};

export default Protector;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePreview_container__profile_info__oKkJd {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3px 16px 3px 4px;
  border-radius: 6px;
  background-color: var(--color-base-dark-grey);
  cursor: pointer;
  text-align: left;
  border: none;
}
.ProfilePreview_container__profile_info__oKkJd:hover {
  background-color: var(--color-base-control-secondary-hover);
}

.ProfilePreview_profile_info__name_and_role__kjMnh {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ProfilePreview_profile_info__role__OGqJp {
  color: #fff;
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Topbar/ProfilePreview/ProfilePreview.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;EACA,kBAAA;EACA,6CAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AACF;AAAE;EACE,2DAAA;AAEJ;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".container__profile_info {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n  padding: 3px 16px 3px 4px;\n  border-radius: 6px;\n  background-color: var(--color-base-dark-grey);\n  cursor: pointer;\n  text-align: left;\n  border: none;\n  &:hover {\n    background-color: var(--color-base-control-secondary-hover);\n  }\n}\n\n.profile_info__name_and_role {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.profile_info__role {\n  color: #fff;\n  opacity: 0.6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container__profile_info": `ProfilePreview_container__profile_info__oKkJd`,
	"profile_info__name_and_role": `ProfilePreview_profile_info__name_and_role__kjMnh`,
	"profile_info__role": `ProfilePreview_profile_info__role__OGqJp`
};
export default ___CSS_LOADER_EXPORT___;

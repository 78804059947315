import { toast } from 'react-toastify';
import Toast from '../../ui/components/Toast/Toast';

const ShowSuccessToast = (message: string) => {
  toast(
    <Toast
      title="Success"
      message={message}
      type="success"
      />,
    {
      position: 'top-right',
      closeButton: false,
      autoClose: 1500,
      hideProgressBar: true,
    },
  );
};

export default ShowSuccessToast;

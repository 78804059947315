export enum SocketEventsENUM {
  connect = 'connect',
  disconnect = 'disconnect',
  error = 'error',
  connectError = 'connect_error',
  customError = 'SEND_ERROR',
  forceLogout = 'force_logout',
  hello = 'hello',

  emit_requestTakeToWork = 'request_take_to_work',
  emit_requestsClose = 'requests_close',

  emit_chatGetChat = 'chat_get_chat',
  emit_chatGetList = 'chat_get_list',
  emit_chatGetMessages = 'chat_get_messages',
  emit_chatCreateMessage = 'chat_create_message',
  emit_chatCreateChat = 'chat_create_chat',
  emit_chatReadMessages = 'chat_read_messages',

  on_requestsNewRequest = 'requests_new_request',
  on_requestsStatusChange = 'requests_status_change',
  on_chatNewMessage = 'chat_new_message',
  on_chatReadMessages = 'chat_read_messages',
  // uses for getting last message user read date
  on_chatUpdateParticipant = 'chat_update_participant',
}

export const routerConstants = {
  home: '/',
  transactions: '/transactions',
  permissionsNavigate: '/permissions-navigate',
  meets: '/meets',
  support: '/support',
  users: '/users',
  moderators: '/moderators',
  login: '/login',
  profile: '/profile',
  resetPassword: '/reset-password',

  chat: '/chat/:id',
  getChatURL: (id: string) => `/chat/${id}`,
} as const;

export enum PermissionsENUM {
  FINANCE_MANAGEMENT = 'FINANCE_MANAGEMENT',
  MEETS_MANAGEMENT = 'MEETS_MANAGEMENT',
  USERS_VERIFICATION = 'USERS_VERIFICATION',
  USERS_MANAGEMENT = 'USERS_MANAGEMENT',
  USERS_SUPPORT = 'USERS_SUPPORT',
}

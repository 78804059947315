import React from 'react';
import cn from 'classnames';

import type { ISidebarField } from './sidebarFields';

import styles from './Sidebar.module.scss';
import SidebarLink from './SidebarLink/SidebarLink';

type PropsType = {
  sidebarFields: ISidebarField[];
};

const SideBar: React.FC<PropsType> = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const timerId = React.useRef<NodeJS.Timeout>();

  const handleSidebarOpen = () => {
    clearTimeout(timerId.current);
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    timerId.current = setTimeout(() => {
      setIsSidebarOpen(false);
    }, 300);
  };

  return (
    <div
      className={cn(styles.sidebar__container, {
        [styles.container_visible]: isSidebarOpen,
      })}
      onMouseOver={handleSidebarOpen}
      onMouseLeave={handleSidebarClose}
    >
      <div className={styles.sidebar__content}>
        {props.sidebarFields.map((field) => (
          <SidebarLink key={field.name} field={field} isOpen={isSidebarOpen} />
        ))}
      </div>
    </div>
  );
};

export default SideBar;

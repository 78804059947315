import axios from 'axios';
import storage from 'src/utils/storage';
import config from 'src/config';
import { PathENUM } from '../constans';

type RefreshTokenResponseType =
  { isRefreshed: true; accessToken: string } |
  { isRefreshed: false; accessToken: null };

const refreshToken = async (): Promise<RefreshTokenResponseType> => {
  try {
    const refreshToken = `Bearer ${storage.refreshToken.get()}`;

    const response = await axios.post<{ accessToken: string; refreshToken: string }>(`${config.apiUrl}${PathENUM.AUTH}/refresh`, { token: refreshToken });

    storage.accessToken.set(response.data.accessToken);
    storage.refreshToken.set(response.data.refreshToken);

    return { isRefreshed: true, accessToken: response.data.accessToken };
  } catch {
    return { isRefreshed: false, accessToken: null };
  }
};

let refreshingPromise: ReturnType<typeof refreshToken> | null = null;

export default async () => {
  if (!refreshingPromise) {
    refreshingPromise = refreshToken();
  }
  const result = await refreshingPromise;
  refreshingPromise = null;
  return result;
};

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { IAdmin } from 'src/types';
import thunks from './mainSlice.thunks';

let isLoaded = false;

type InitialStateType = {
  admin: IAdmin | null;
  isAuthChecked: boolean;
  socketConnectionStatus: boolean;
};

const getInitialStore = (): InitialStateType => ({
  admin: null as IAdmin | null,
  isAuthChecked: isLoaded,
  socketConnectionStatus: false,
});

const mainSlice = createSlice({
  name: 'main',
  initialState: getInitialStore,
  reducers: {
    setAdmin: (store, { payload }: PayloadAction<IAdmin | null>) => {
      store.admin = payload;
    },

    setConnectionStatus: (store, { payload }: PayloadAction<boolean>) => {
      store.socketConnectionStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.authorize.fulfilled, (store, { payload }) => {
      store.isAuthChecked = true;
      isLoaded = true;
      store.admin = payload;
    });
    builder.addCase(thunks.authorize.rejected, (store) => {
      store.isAuthChecked = true;
      isLoaded = true;
    });

    builder.addCase(thunks.signIn.fulfilled, (store, { payload }) => {
      store.admin = payload;
    });
    builder.addCase(thunks.updateAdmin.fulfilled, (store, { payload }) => {
      store.admin = payload;
    });
    builder.addCase(thunks.updateAdminAvatar.fulfilled, (store, { payload }) => {
      store.admin = payload;
    });
  },
});

export const mainSliceActions = mainSlice.actions;

export default mainSlice.reducer;

import type { IMedia, IMediaResponse } from 'src/types/mediaItemType';

export class MediaDataHelper {
  public static TransformMediaItemFromResponse(media: IMediaResponse): IMedia {
    return {
      key: media.key,
      link: media.link ?? null,
      mediaItemId: media.mediaItemId,
      adminUploadedId: media.adminUploadedId,
      createdAt: media.createdAt,
      deletedAt: media.deletedAt,
      mimeType: media.mimeType,
      thumbnailKey: media.thumbnailKey,
      updatedAt: media.updatedAt,
      userUploadedId: media.userUploadedId,
      thumbLink: media.thumbLink ?? null,
    };
  }

  public static TransformMediaItemsFromResponse(mediaItems: IMediaResponse[] | undefined) {
    if (!mediaItems) return [];

    return mediaItems.map(MediaDataHelper.TransformMediaItemFromResponse);
  }
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '../Sidebar/Sidebar';
import {
  type ISidebarField,
  sidebarFields as originSidebarFields,
} from '../Sidebar/sidebarFields';

import styles from './Layout.module.scss';
import { useAppSelector } from '../../../store/store';
import { routerConstants } from '../../../core/routes/constants';
import Topbar from '../Topbar/Topbar';
import { notifications } from './mocks/notificationMock';

const Layout = () => {
  const admin = useAppSelector(({ main }) => main.admin);

  const sidebarFields = React.useMemo(() => {
    return originSidebarFields.map((item): ISidebarField => {
      if (item.href === routerConstants.users) {
        return {
          ...item,
          additionalInfo: '100',
          hasUnreadMessage: true,
        };
      }

      if (item.href === routerConstants.support) {
        return {
          ...item,
          additionalInfo: '1000',
          hasUnreadMessage: true,
        };
      }
      return item;
    });
  }, []);

  return (
    <div className={styles.container}>
      <Topbar
        notifications={notifications}
        name={admin?.name}
        role={admin!.role.name}
        userAvatar={admin?.avatar?.link}
      />
      <SideBar sidebarFields={sidebarFields} />
      <main>
        <React.Suspense>
          <Outlet />
        </React.Suspense>
      </main>
    </div>
  );
};

export default Layout;

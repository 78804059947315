import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ResponseWithPaginationType } from 'src/api/http/http.types';
import type { GetOffersReqParamsType } from 'src/api/offersApi';
import offersApi from 'src/api/offersApi';
import userApi from 'src/api/userApi';
import type { IOffer } from 'src/types';
import { showErrorMessageByCode } from 'src/utils/handleValidationError';
import ShowErrorToast from 'src/utils/showToast/ShowErrorToast';
import ShowSuccessToast from 'src/utils/showToast/ShowSuccessToast';

const getOffersByUserIdThunk = createAsyncThunk<
  ResponseWithPaginationType<{ offers: IOffer[] }>,
  { id: number; page: number; infinity?: boolean }
>('offers/getOffersByUserId', async (params) => {
  const { data } = await userApi.getUserOffers(params);
  return data;
});

const getMeetingsByUserIdThunk = createAsyncThunk<
  ResponseWithPaginationType<{ offers: IOffer[] }>,
  { id: number; page: number; infinity?: boolean }
>('offers/getMeetingsByUserId', async (params) => {
  const { data } = await userApi.getUserMeetings(params);
  return data;
});

const getAllOffersThunk = createAsyncThunk<
  ResponseWithPaginationType<{ offers: IOffer[] }>,
  GetOffersReqParamsType
>('offers/getAllOffers', async (params, { rejectWithValue }) => {
  try {
    const { data } = await offersApi.getAllOffers(params);
    return data;
  } catch (err) {
    ShowErrorToast('Failed to get offers');
    return rejectWithValue(err);
  }
});

const cancelOfferThunk = createAsyncThunk<void, { offerId: number }>(
  'offers/cancelOffer',
  async ({ offerId }, { rejectWithValue }) => {
    try {
      const { data } = await offersApi.cancelOffer(offerId);
      ShowSuccessToast('Offer successfully canceled');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        403: 'This offer cannot be cancelled or You are not allowed to cancel this offer',
        404: 'Offer not found',
        400: 'Failed to cancel offer',
        500: 'Failed to cancel offer because of server internal error, try again later',
      });
      return rejectWithValue(err);
    }
  },
);

const getOfferByIdThunk = createAsyncThunk<
  { offer: IOffer },
  {
    offerId: IOffer['offerId'];
  }
>('offers/getOffersById', async ({ offerId }, { rejectWithValue }) => {
  try {
    const { data } = await offersApi.getOfferById(offerId);

    return {
      offer: data,
    };
  } catch (err) {
    ShowErrorToast('Failed to get offer');
    return rejectWithValue(err);
  }
});

export const offersThunks = {
  getOffersByUserIdThunk,
  getMeetingsByUserIdThunk,
  getAllOffersThunk,
  getOfferByIdThunk,
  cancelOfferThunk,
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_dropdown__xKVBN {
  position: absolute;
  top: calc(40% + 25px);
  right: 24px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}

.Dropdown_visible__Agd8L {
  opacity: 1;
  transition: opacity 0.2s ease-out;
  pointer-events: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(179, 179, 179, 0.1019607843), 2px 7px 7px 0px rgba(179, 179, 179, 0.0901960784), 3px 16px 10px 0px rgba(179, 179, 179, 0.0509803922), 6px 28px 12px 0px rgba(179, 179, 179, 0.0117647059), 10px 44px 13px 0px rgba(179, 179, 179, 0);
  z-index: 99999;
}

.Dropdown_text__i-Vb7 {
  font-size: 13px;
}

.Dropdown_dropdown__item__WhtZK {
  padding: 10px 116px 10px 15px;
  display: flex;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Dropdown_error__mxbBs {
  color: var(--color-base-error);
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,UAAA;EACA,oBAAA;EACA,eAAA;AACF;;AAEA;EACE,UAAA;EACA,iCAAA;EACA,oBAAA;EACA,uBAAA;EACA,kBAAA;EACA,qQAAA;EAKA,cAAA;AAHF;;AAMA;EACE,eAAA;AAHF;;AAMA;EACE,6BAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAHF;;AAMA;EACE,8BAAA;AAHF","sourcesContent":[".dropdown {\n  position: absolute;\n  top: calc(40% + 25px);\n  right: 24px;\n  opacity: 0;\n  pointer-events: none;\n  cursor: pointer;\n}\n\n.visible {\n  opacity: 1;\n  transition: opacity 0.2s ease-out;\n  pointer-events: auto;\n  background-color: white;\n  border-radius: 8px;\n  box-shadow: 0px 2px 4px 0px #B3B3B31A,\n              2px 7px 7px 0px #B3B3B317,\n              3px 16px 10px 0px #B3B3B30D,\n              6px 28px 12px 0px #B3B3B303,\n              10px 44px 13px 0px #B3B3B300;\n  z-index: 99999;\n}\n\n.text {\n  font-size: 13px;\n}\n\n.dropdown__item {\n  padding: 10px 116px 10px 15px;\n  display: flex;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.error {\n  color: var(--color-base-error);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `Dropdown_dropdown__xKVBN`,
	"visible": `Dropdown_visible__Agd8L`,
	"text": `Dropdown_text__i-Vb7`,
	"dropdown__item": `Dropdown_dropdown__item__WhtZK`,
	"error": `Dropdown_error__mxbBs`
};
export default ___CSS_LOADER_EXPORT___;

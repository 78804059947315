// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_container__\\+jpsu {
  display: flex;
  height: 100vh;
}

main {
  padding-top: 60px;
  padding-left: 88px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Layout/Layout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  height: 100vh;\n}\n\nmain {\n  padding-top: 60px;\n  padding-left: 88px;\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Layout_container__+jpsu`
};
export default ___CSS_LOADER_EXPORT___;

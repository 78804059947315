import React from 'react';
import { Text } from '@consta/uikit/Text';
import { dayjsGBLocale } from 'src/utils/dayjsWithLocale';
import type { NotificationType } from '../types';

import styles from './NotificationItem.module.scss';

type PropsType = NotificationType;

const NotificationItem: React.FC<PropsType> = (props) => {
  const dateString = React.useMemo(() => {
    return dayjsGBLocale(props.date).format('MMM D · hh:mm A');
  }, [props.date]);
  return (
    <div className={styles.container}>
      <div className={styles.container__text}>
        <Text
          size="s"
          weight="semibold"
          font="mono"
          lineHeight="s"
          >
          {props.label}
        </Text>
        <Text
          className={styles.text__description}
          font="mono"
          lineHeight="xs"
          view="link"
        >
          {props.description}
        </Text>
      </div>
      <Text
        view="brand"
        size="s"
        lineHeight="xs"
      >
        {dateString}
      </Text>
    </div>
  );
};

export default NotificationItem;

import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { EntityState } from '@reduxjs/toolkit';

import type { FindAndCountMetaType, IUser } from 'src/types';

import { chatThunks } from 'src/store/chat/thunks';
import { usersEntityAdapter } from './entityAdapter';
import { usersThunks } from './thunks';
import { UserStoreHelper } from './helpers';
import { offersThunks } from '../offers/thunks';

type UserStateType = {
  users: EntityState<IUser>;
  pagination: FindAndCountMetaType | null;
  currentPageItems: number[];
};

const initialState: UserStateType = {
  users: usersEntityAdapter.getInitialState(),
  pagination: null,
  currentPageItems: [],
};

const isOffersLoaded = isAnyOf(
  offersThunks.getOffersByUserIdThunk.fulfilled,
  offersThunks.getMeetingsByUserIdThunk.fulfilled,
  offersThunks.getAllOffersThunk.fulfilled,
);

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(usersThunks.getAllUsersThunk.fulfilled, (store, action) => {
      store.pagination = action.payload.pagination;
      usersEntityAdapter.setMany(store.users, action.payload.users);
      store.currentPageItems = action.payload.users.map(({ userId }) => userId);
    });

    builder.addCase(usersThunks.getUserByIdThunk.fulfilled, (store, action) => {
      if (!action.payload) {
        return;
      }
      usersEntityAdapter.upsertOne(store.users, action.payload);
    });

    builder.addCase(usersThunks.acceptVerificationRequestThunk.fulfilled, (store, action) => {
      usersEntityAdapter.updateOne(store.users, {
        id: action.payload.userId,
        changes: {
          ...action.payload,
          kycVerifications: action.payload.kycVerifications,
        },
      });
    });

    builder.addCase(usersThunks.rejectVerificationRequestThunk.fulfilled, (store, action) => {
      usersEntityAdapter.updateOne(store.users, {
        id: action.payload.user.userId,
        changes: {
          ...action.payload.user,
          kycVerifications: action.payload.user.kycVerifications,
        },
      });
    });

    builder.addCase(chatThunks.getChatsThunk.fulfilled, (store, action) => {
      const users: Record<number, IUser> = {};
      const {
        payload: { chats },
      } = action;

      chats.forEach((chat) => {
        chat.participants.forEach(({ user }) => {
          if (user) {
            const userId = user.userId;
            users[userId] = user;
          }
        });
      });

      usersEntityAdapter.upsertMany(store.users, users);
    });

    builder.addMatcher(
      isAnyOf(usersThunks.unbanUserThunk.pending, usersThunks.banUserThunk.rejected),
      (store, action) => {
        UserStoreHelper.updateRestrictions(store.users, {
          userId: action.meta.arg.userId,
          changes: {
            isBanned: false,
          },
        });
      },
    );

    builder.addMatcher(
      isAnyOf(usersThunks.banUserThunk.pending, usersThunks.unbanUserThunk.rejected),
      (store, action) => {
        UserStoreHelper.updateRestrictions(store.users, {
          userId: action.meta.arg.userId,
          changes: {
            isBanned: true,
          },
        });
      },
    );

    builder.addMatcher(
      isAnyOf(usersThunks.unblockUserThunk.pending, usersThunks.blockUserThunk.rejected),
      (store, action) => {
        UserStoreHelper.updateRestrictions(store.users, {
          userId: action.meta.arg.userId,
          changes: {
            isBlocked: false,
          },
        });
      },
    );

    builder.addMatcher(
      isAnyOf(usersThunks.blockUserThunk.pending, usersThunks.unblockUserThunk.rejected),
      (store, action) => {
        UserStoreHelper.updateRestrictions(store.users, {
          userId: action.meta.arg.userId,
          changes: {
            isBlocked: true,
          },
        });
      },
    );

    builder.addMatcher(isOffersLoaded, (store, action) => {
      const users: Record<number, IUser> = {};

      action.payload.offers.forEach((i) => {
        users[i.author.userId] = i.author;
        if (i.customer?.userId) {
          users[i.customer.userId] = i.customer;
        }
      });

      usersEntityAdapter.upsertMany(store.users, users);
    });
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;

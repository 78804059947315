export enum EnvTypeENUM {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

export const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || EnvTypeENUM.development;
export const isDev = envType === EnvTypeENUM.development;
export const isProd = envType === EnvTypeENUM.production;
export const isStage = envType === EnvTypeENUM.staging;

const config = {
  apiUrl: 'https://dev.bfriendz.fusion-team.com/api',
  socketUrl: 'wss://dev.bfriendz.fusion-team.com',
  maxNumberOfAttempts: 100,
};

// eslint-disable-next-line default-case
switch (envType) {
  case EnvTypeENUM.production:
    config.apiUrl = 'https://dev.bfriendz.fusion-team.com/api';
    config.socketUrl = 'wss://dev.bfriendz.fusion-team.com';
    break;

  case EnvTypeENUM.staging:
    config.apiUrl = 'https://dev.bfriendz.fusion-team.com/api';
    config.socketUrl = 'wss://dev.bfriendz.fusion-team.com';
    break;
}

export default config;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationItem_container__Eb2kQ {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: baseline;
}

.NotificationItem_text__description__Z0eE2 {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Notifications/NotificationItem/NotificationItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  padding: 12px 0;\n  align-items: baseline;\n}\n\n.text__description {\n  font-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotificationItem_container__Eb2kQ`,
	"text__description": `NotificationItem_text__description__Z0eE2`
};
export default ___CSS_LOADER_EXPORT___;

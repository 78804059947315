// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toast_toast__vxSKm {
  width: 300px;
  padding: 16px 16px 16px 24px;
  border-left: 8px solid var(--color-base-alert);
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: var(--color-base-font-primary);
}

.Toast_toast_error__1pTSf {
  border-left: 8px solid var(--color-base-alert);
}

.Toast_toast_success__aOjDw {
  border-left: 8px solid var(--color-base-success);
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Toast/Toast.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,4BAAA;EACA,8CAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;EACA,qCAAA;AACF;;AAEA;EACE,8CAAA;AACF;;AAEA;EACE,gDAAA;AACF","sourcesContent":[".toast {\n  width: 300px;\n  padding: 16px 16px 16px 24px;\n  border-left: 8px solid var(--color-base-alert);\n  border-radius: 8px;\n  background-color: white;\n  display: flex;\n  align-items:flex-start;\n  gap: 12px;\n  color: var( --color-base-font-primary);\n}\n\n.toast_error {\n  border-left: 8px solid var(--color-base-alert);\n}\n\n.toast_success {\n  border-left: 8px solid var(--color-base-success);\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `Toast_toast__vxSKm`,
	"toast_error": `Toast_toast_error__1pTSf`,
	"toast_success": `Toast_toast_success__aOjDw`
};
export default ___CSS_LOADER_EXPORT___;

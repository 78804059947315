// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-prim:
  'Inter',
  sans-serif;
  --font-sec:  -apple-system, BlinkMacSystemFont;
  --font-third:  'Nunito';
}

.Theme_font_gpnDefault {
  --font-primary: var(--font-prim);
  --font-mono: var(--font-sec);
  font-family: var(--font-primary);
}`, "",{"version":3,"sources":["webpack://./src/ui/defaultPreset/_font/Theme_font_gpnDefault.css"],"names":[],"mappings":"AAAA;EACE;;YAEU;EACV,8CAA8C;EAC9C,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,4BAA4B;EAC5B,gCAAgC;AAClC","sourcesContent":[":root {\n  --font-prim:\n  'Inter',\n  sans-serif;\n  --font-sec:  -apple-system, BlinkMacSystemFont;\n  --font-third:  'Nunito';\n}\n\n.Theme_font_gpnDefault {\n  --font-primary: var(--font-prim);\n  --font-mono: var(--font-sec);\n  font-family: var(--font-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

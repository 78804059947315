import qs from 'query-string';
import http from './http';

import type { FindAndCountMetaType, IOffer } from '../types';
import { PathENUM } from './constans';

type QueryValueType = string | null;
export type GetOffersReqParamsType<Q = QueryValueType> = {
  page?: number;
  s?: Q;
  // comma separate offer statuses
  status?: Q;
  author?: Q;
  customer?: Q;
  created?: Q;
  date?: Q;
  price_from?: Q;
  price_to?: Q;
  location?: Q;
};
const getAllOffers = (params: GetOffersReqParamsType) => {
  return http.get<{
    offers: IOffer[];
    pagination: FindAndCountMetaType;
  }>(PathENUM.OFFER, {
    params,
    paramsSerializer: {
      serialize: (params) => {
        return qs.stringify(params, {
          skipEmptyString: true,
          skipNull: true,
        });
      },
    },
  });
};

const cancelOffer = (offerId: number) => {
  return http.post<void>(`${PathENUM.OFFER}/${offerId}${PathENUM.CANCEL}`);
};

const getOfferById = (offerId: IOffer['offerId']) => {
  return http.get(`${PathENUM.OFFER}/${offerId}`);
};

export default {
  getAllOffers,
  cancelOffer,
  getOfferById,
};

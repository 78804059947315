// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Topbar_topbar__xJu1W {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-base-dark-grey);
  position: fixed;
  width: 100%;
  height: 60px;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 999;
}

.Topbar_topbar__logo__46cEG {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Topbar_topbar__container__MuEcI {
  display: flex;
  align-items: center;
  gap: 16px;
}

.Topbar_container__button_settings__IUr5c {
  border-radius: 6px;
  padding: 10px 9px 10px 9px;
  color: rgba(255, 255, 255, 0.75);
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.Topbar_container__button_settings__IUr5c:hover {
  background-color: var(--color-base-control-secondary-hover);
}

.Topbar_container__button_notification__\\+DYuu {
  border-radius: 6px;
  padding: 4px;
}
.Topbar_container__button_notification__\\+DYuu > span {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Topbar/Topbar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,6CAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,0BAAA;EACA,gCAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,2DAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,YAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".topbar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: var(--color-base-dark-grey);\n  position: fixed;\n  width: 100%;\n  height: 60px;\n  padding: 0 24px;\n  box-sizing: border-box;\n  z-index: 999;\n}\n\n.topbar__logo {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.topbar__container {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.container__button_settings {\n  border-radius: 6px;\n  padding: 10px 9px 10px 9px;\n  color: rgba(255, 255, 255, 0.75);\n  width: 40px;\n  height: 40px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  &:hover {\n    background-color: var(--color-base-control-secondary-hover);\n  }\n}\n\n.container__button_notification {\n  border-radius: 6px;\n  padding: 4px;\n  & > span {\n    width: 24px;\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": `Topbar_topbar__xJu1W`,
	"topbar__logo": `Topbar_topbar__logo__46cEG`,
	"topbar__container": `Topbar_topbar__container__MuEcI`,
	"container__button_settings": `Topbar_container__button_settings__IUr5c`,
	"container__button_notification": `Topbar_container__button_notification__+DYuu`
};
export default ___CSS_LOADER_EXPORT___;

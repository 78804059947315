import { MessageTypeENUM, type ITempMessage } from 'src/types';

export class MessageDataHelper {
  public static createTempMessageEntity(
    params: {
      chatId: number;
      author: number;
      text: string;
      mediaItems: ITempMessage['mediaItems'];
      createdAt: string;
    },
    tempId: ITempMessage['messageId'],
  ): ITempMessage {
    return {
      chatId: params.chatId,
      authorId: params.author,
      text: params.text,
      mediaItems: params.mediaItems,
      messageId: tempId,
      createdAt: params.createdAt,
      errored: false,
      type: MessageTypeENUM.DEFAULT,
    } satisfies ITempMessage;
  }
}

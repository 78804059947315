import type { NotificationType } from '../../Notifications/types';

export const notifications: NotificationType[] = [
  {
    label: 'Verification request',
    description: 'Margaret Castro',
    date: new Date('2022-12-12T11:10:00.000Z'),
  },
  {
    label: 'Opened a chat room with a user ',
    description: 'Richard Davis',
    date: new Date('2022-09-15T16:20:00.000Z'),
  },
  {
    label: ' Opened',
    description: 'Richard ads',
    date: new Date('2022-10-22T15:20:00.000Z'),
  },
  {
    label: 'Opened a chat  ',
    description: 'Richard asd',
    date: new Date('2022-11-12T09:20:00.000Z'),
  },
  {
    label: 'Opened a chat asdasdasd ',
    description: 'Richard Davfdis',
    date: new Date('2022-12-12T12:20:00.000Z'),
  },
  {
    label: 'Opened a chat asdasfvvzv ',
    description: 'Richard fdga',
    date: new Date('2022-12-12T12:20:00.000Z'),
  },
  {
    label: 'Opened a chat room  ',
    description: 'Richard qwe',
    date: new Date('2022-12-12T12:20:00.000Z'),
  },
];

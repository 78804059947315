// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__container__UjxjX {
  background-color: var(--color-base-dark-grey);
  height: 100%;
  width: 88px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: width 100ms linear;
  position: fixed;
  margin-top: 60px;
  z-index: 99;
}

.Sidebar_sidebar__content__nzl8s {
  padding: 40px 16px;
}

.Sidebar_container_visible__NkGNB {
  width: 273px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".sidebar__container {\n  background-color: var(--color-base-dark-grey);\n  height: 100%;\n  width: 88px;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  transition: width 100ms linear;\n  position: fixed;\n  margin-top: 60px;\n  z-index: 99;\n}\n\n.sidebar__content {\n  padding: 40px 16px;\n}\n\n.container_visible {\n  width: 273px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar__container": `Sidebar_sidebar__container__UjxjX`,
	"sidebar__content": `Sidebar_sidebar__content__nzl8s`,
	"container_visible": `Sidebar_container_visible__NkGNB`
};
export default ___CSS_LOADER_EXPORT___;

import { createAsyncThunk } from '@reduxjs/toolkit';
import penaltiesApi from 'src/api/penaltiesApi';
import userApi from 'src/api/userApi';

import ShowErrorToast from 'src/utils/showToast/ShowErrorToast';
import ShowSuccessToast from 'src/utils/showToast/ShowSuccessToast';

import { showErrorMessageByCode } from 'src/utils/handleValidationError';

import type { ResponseWithPaginationType } from 'src/api/http/http.types';
import type { GetUsersListParamsType } from 'src/api/userApi';
import type { IKYCVerification, IUser } from 'src/types';
import KYCApi from 'src/api/KYCApi';
import type { AppStateType } from '../store';

const getAllUsersThunk = createAsyncThunk<
  ResponseWithPaginationType<{ users: IUser[] }>,
  GetUsersListParamsType
>(
  'users/getAllUsers',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await userApi.getAllUsers(params);
      return data;
    } catch (err) {
      ShowErrorToast('Failed to get users');
      return rejectWithValue(err);
    }
  },
);

const getUserByIdThunk = createAsyncThunk<IUser | undefined, number, { state: AppStateType }>(
  'users/getUserById',
  async (id, { getState }) => {
    const existingUser = getState().usersState.users.entities[id];
    if (existingUser) {
      return;
    }
    const { data } = await userApi.getUserById(id);
    return data;
  },
);

const blockUserThunk = createAsyncThunk<void, { userId: number }>(
  'users/blockUser',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await penaltiesApi.blockUser(userId);
      ShowSuccessToast('User has been successfully blocked');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        409: 'User already blocked or banned',
      });
      return rejectWithValue(err);
    }
  },
);

const unblockUserThunk = createAsyncThunk<void, { userId: number }, { state: AppStateType }>(
  'users/unblockUser',
  async ({ userId }, { getState, rejectWithValue }) => {
    const user = getState().usersState.users.entities[userId];
    if (user?.restrictions?.isBanned) {
      ShowErrorToast('User cannot be unblocked because user has been banned.');
      return rejectWithValue('User cannot be unblocked because user has been banned.');
    }
    try {
      const { data } = await penaltiesApi.unblockUser(userId);
      ShowSuccessToast('User has been successfully unblocked');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        409: 'User is not blocked or has already been banned',
      });
      rejectWithValue(err);
    }
  },
);

const banUserThunk = createAsyncThunk<void, { userId: number }>(
  'users/banUser',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await penaltiesApi.banUser(userId);
      ShowSuccessToast('User has been successfully banned');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        409: 'User is already banned',
      });
      return rejectWithValue(err);
    }
  },
);

const unbanUserThunk = createAsyncThunk<void, { userId: number }>(
  'users/unbanUser',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await penaltiesApi.unbanUser(userId);
      ShowSuccessToast('User has been successfully unbanned');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        409: 'User is not banned',
      });
      return rejectWithValue(err);
    }
  },
);

// VERIFICATION FLOW

const acceptVerificationRequestThunk = createAsyncThunk<IUser, { kycVerificationId: number }>(
  'users/acceptVerificationRequest',
  async ({ kycVerificationId }, { rejectWithValue, signal }) => {
    try {
      const { data } = await KYCApi.acceptRequest(kycVerificationId, { abortSignal: signal });
      ShowSuccessToast('User verification request accepted');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        403: 'You are not allowed to perform this action',
        404: 'KYC request not found',
        409: `There are several reasons: 
        1."Request has already been handled."
        2."User has already been verified."
        3."KYC verification has failed."`,
      });
      return rejectWithValue(err);
    }
  },
);

const rejectVerificationRequestThunk = createAsyncThunk<
  IKYCVerification,
  { kycVerificationId: number; comment?: string }
>(
  'users/rejectVerificationRequest',
  async ({ kycVerificationId, comment }, { rejectWithValue, signal }) => {
    try {
      const { data } = await KYCApi.rejectRequest(
        kycVerificationId,
        comment,
        { abortSignal: signal },
      );
      ShowSuccessToast('User verification request rejected');
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        403: 'You are not allowed to perform this action',
        404: 'KYC request not found',
        409: 'Request has already been handled',
      });
      return rejectWithValue(err);
    }
  },
);

const getUserVerificationRequestByIdThunk = createAsyncThunk<
  IKYCVerification,
  { userId: number }
>(
  'users/getUserVerificationRequestById',
  async ({ userId }, { rejectWithValue, signal }) => {
    try {
      const { data } = await KYCApi.getRequestById(userId, { abortSignal: signal });
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        404: 'User not found',
        403: 'You are not allowed to perform this action',
      });
      return rejectWithValue(err);
    }
  },
);

const getVerificationByIdThunk = createAsyncThunk<
  IKYCVerification,
  { kycVerificationId: number }
>(
  'users/getVerificationById',
  async ({ kycVerificationId }, { rejectWithValue, signal }) => {
    try {
      const { data } = await KYCApi.getRequestById(kycVerificationId, { abortSignal: signal });
      return data;
    } catch (err) {
      showErrorMessageByCode(err, {
        401: 'Unauthorized',
        404: 'KYC request not found',
        403: 'You are not allowed to perform this action',
      });
      return rejectWithValue(err);
    }
  },
);

export const usersThunks = {
  getAllUsersThunk,
  getUserByIdThunk,
  blockUserThunk,
  unblockUserThunk,
  banUserThunk,
  unbanUserThunk,

  acceptVerificationRequestThunk,
  rejectVerificationRequestThunk,
  getUserVerificationRequestByIdThunk,
  getVerificationByIdThunk,
};

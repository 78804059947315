import { createAsyncThunk } from '@reduxjs/toolkit';
import { mainSocket } from 'src/api/ws/MainSocket';
import { SocketEventsENUM } from 'src/api/ws/socketEvents';
import type {
  IChatResponse,
  IChat,
  IMessageResponse,
  IReportResponse,
  IParticipantResponse,
  IParticipant,
  IBaseMessage,
} from 'src/types';
import mediaApi from 'src/api/media';
import type { ITempMedia } from 'src/types/mediaItemType';
import helpers from 'src/utils/helpers';
import ShowErrorToast from 'src/utils/showToast/ShowErrorToast';
import type { IRequestFiltersChats } from 'src/pages/SupportPage/utils/filters';
import { chatSelectors } from 'src/store/chat/selectors';
import { ChatDataHelper } from './helpers/ChatDataHelper';
import type { AppStateType } from '../store';

let chatsListOldFilters: IRequestFiltersChats | null = null;

const getChatsThunk = createAsyncThunk<
  {
    rewriteChats: boolean;
    selectedChat: {
      chat: IChat;
      messages: IBaseMessage[];
      participants: IParticipant[];
    } | null;
    chats: IChatResponse[];
  },
  {
    filters: IRequestFiltersChats;
  },
  { state: AppStateType }
>('chat/getChats', async ({ filters }, { rejectWithValue, getState }) => {
  try {
    const selectedChatId = chatSelectors.getSelectedChatIdSelector(getState());

    let selectedChat: {
      chat: IChat;
      messages: IBaseMessage[];
      participants: IParticipant[];
    } | null = null;
    const filtersChanged = !helpers.deepEqual(chatsListOldFilters, filters);

    const responseChatList = await mainSocket.emit<{
      chats: IChatResponse[];
    }>(SocketEventsENUM.emit_chatGetList, {
      excludeIds: !filtersChanged ? chatSelectors.getChatIdsSelector(getState()) : [],
      ...filters,
    });
    chatsListOldFilters = filters;

    if (selectedChatId !== null) {
      const foundSelectedChatInResponse = responseChatList.payload.chats.some(
        (chat) => chat.chatId === selectedChatId,
      );

      const localSelectedChat = chatSelectors.chatEntitySelectors.selectById(
        getState(),
        selectedChatId,
      );

      if (localSelectedChat) {
        selectedChat = {
          chat: {
            ...localSelectedChat,
            isFiltered: foundSelectedChatInResponse || !filtersChanged,
          },
          messages: chatSelectors.getMessagesByChatIdSelector(getState(), localSelectedChat.chatId),
          participants: chatSelectors.chatParticipantsSelector(
            getState(),
            localSelectedChat.chatId,
          ),
        };
      } else if (!foundSelectedChatInResponse) {
        const chatResponse = await mainSocket.emit<{
          chat: IChatResponse;
        }>(SocketEventsENUM.emit_chatGetChat, {
          chatId: selectedChatId,
        });

        const transformedSelectedChat = ChatDataHelper.TransformChatFromResponse(
          chatResponse.payload.chat,
        );
        transformedSelectedChat.chat.isFiltered = false;

        selectedChat = {
          chat: transformedSelectedChat.chat,
          participants: transformedSelectedChat.participants,
          messages: [],
        };
      }
    }

    return {
      rewriteChats: filtersChanged,
      selectedChat,
      chats: responseChatList.payload.chats,
    };
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

const getChatMessagesThunk = createAsyncThunk<
  {
    messages: IMessageResponse[];
  },
  {
    chatId: number;
    participantId?: number;
  }
>('chat/getChatMessages', async (params, { rejectWithValue }) => {
  try {
    const response = await mainSocket.emit<{
      messages: IMessageResponse[];
    }>(SocketEventsENUM.emit_chatGetMessages, params);

    return response.payload;
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

const createNewChatThunk = createAsyncThunk<
  {
    chat: IChatResponse;
  },
  {
    userId: number;
  },
  { state: AppStateType }
>('chat/createNewChat', async ({ userId }, { getState, rejectWithValue }) => {
  const adminId = getState().main.admin?.adminId;
  try {
    const response = await mainSocket.emit<{
      chat: IChatResponse;
    }>(SocketEventsENUM.emit_chatCreateChat, {
      userId,
      adminId,
    });

    return response.payload;
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

const createNewMessageThunk = createAsyncThunk<
  { message: IMessageResponse },
  {
    text: string;
    mediaItems: ITempMedia[];
    chatId: number;
    author: number;
    createdAt: string;
  }
>('chat/createNewMessage', async (params, { rejectWithValue }) => {
  try {
    const mediaItemsPromises = params.mediaItems.map(async (media) => {
      const base64 = await helpers.getBase64FromUrl(media.url);

      const response = await mediaApi.uploadFile({
        base64: base64 as string,
        name: media.name,
      });

      return response.data.mediaItemId;
    });

    const mediaItems = (await Promise.allSettled(mediaItemsPromises)).reduce((prev, cur, index) => {
      if (cur.status !== 'fulfilled') {
        ShowErrorToast(`${params.mediaItems[index].name} upload error`);

        return prev;
      }

      prev.push(cur.value);

      return prev;
    }, [] as number[]);

    if (!mediaItems.length && !params.text.length) {
      throw new Error();
    }

    const response = await mainSocket.emit<{
      message: IMessageResponse;
    }>(SocketEventsENUM.emit_chatCreateMessage, {
      text: params.text,
      chatId: params.chatId,
      mediaItems,
    });

    return response.payload;
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

const chatReadMessagesThunk = createAsyncThunk<
  {
    participant: IParticipantResponse;
  },
  {
    lastViewedDate: string;
    chatId: number;
    participantId: number;
    readMessagesCount: number;
  }
>('chat/chatReadMessages', async ({ readMessagesCount, ...params }, { rejectWithValue }) => {
  try {
    const response = await mainSocket.emit<{ participant: IParticipantResponse }>(
      SocketEventsENUM.emit_chatReadMessages,
      params,
    );

    return response.payload;
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

const getRequestToWorkThunk = createAsyncThunk<
  void,
  {
    reportId: number;
    chatId: IChat['chatId'];
  },
  { state: AppStateType }
>('chat/requestToWork', async (params, { rejectWithValue }) => {
  try {
    await mainSocket.emit(SocketEventsENUM.emit_requestTakeToWork, {
      reportId: params.reportId,
    });
  } catch (err) {
    console.error(err);
    return rejectWithValue(err);
  }
});

const closeRequestThunk = createAsyncThunk<IReportResponse, { reportId: number; chatId: number }>(
  'chat/closeRequest',
  async (params, { rejectWithValue }) => {
    try {
      const response = await mainSocket.emit<{
        report: IReportResponse;
      }>(SocketEventsENUM.emit_requestsClose, { reportId: params.reportId });

      return response.payload.report;
    } catch (err) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const chatThunks = {
  getChatsThunk,
  getChatMessagesThunk,
  createNewChatThunk,
  chatReadMessagesThunk,
  getRequestToWorkThunk,
  createNewMessageThunk,
  closeRequestThunk,
};

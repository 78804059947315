import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IAdmin } from 'src/types';
import storage from 'src/utils/storage';
import authApi from 'src/api/authApi';
import { showErrorMessageByCode } from 'src/utils/handleValidationError';
import adminApi from '../../api/adminApi';

const authorize = createAsyncThunk<IAdmin | null>(
  'main/authorizeAdmin',
  async () => {
    const storedAccessToken = storage.accessToken.get();
    if (!storedAccessToken) {
      throw new Error('No available tokens provided');
    }

    const response = await authApi.getMe();

    return response.data;
  },
);

const signIn = createAsyncThunk(
  'main/signIn',
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await authApi.signIn(data);

      return response.data.user;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateAdmin = createAsyncThunk(
  'main/updateAdmin',
  async (
    payload: {
      data: { email?: string; name?: string | null; phone?: string | null };
      adminId?: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await adminApi.updateAdmin(payload);

      return response.data;
    } catch (error) {
      showErrorMessageByCode(error, {
        400: 'Failed to update admin. Please, try again later',
        401: 'Unauthorized',
      });
      return rejectWithValue(error);
    }
  },
);

const updateAdminAvatar = createAsyncThunk(
  'main/updateAdminAvatar',
  async (
    data: { file: string | ArrayBuffer; name: string},
    { rejectWithValue },
  ) => {
    try {
      const response = await adminApi.updateAvatar(data);
      return response.data;
    } catch (error) {
      showErrorMessageByCode(error, {
        400: 'Failed to update avatar. Please, try again later',
        401: 'Unauthorized',
      });
      return rejectWithValue(error);
    }
  },
);

export default {
  authorize,
  signIn,
  updateAdmin,
  updateAdminAvatar,
};

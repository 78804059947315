// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Theme_control_gpnDefault {
  --control-radius: 8px; /* Основной радиус скругления контроллов */
  --control-border-width: 1px; /* Основная толщина контроллов */
  --control-height-l: var(--space-4xl);
  --control-height-m: var(--space-3xl);
  --control-height-s: var(--space-2xl);
  --control-height-xs: var(--space-xl);
  --control-box-size-s: var(--space-s);
  --control-box-size-m: var(--space-m);
  --control-box-size-l: var(--space-l);
  --control-space-l: var(--space-xl);
  --control-space-m: var(--space-l);
  --control-space-s: var(--space-m);
  --control-space-xs: var(--space-s);
  --control-text-size-l: var(--size-text-l);
  --control-text-size-m: var(--size-text-m);
  --control-text-size-s: var(--size-text-s);
  --control-text-size-xs: var(--size-text-xs);
}`, "",{"version":3,"sources":["webpack://./src/ui/defaultPreset/_control/Theme_control_gpnDefault.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB,EAAE,0CAA0C;EACjE,2BAA2B,EAAE,gCAAgC;EAC7D,oCAAoC;EACpC,oCAAoC;EACpC,oCAAoC;EACpC,oCAAoC;EACpC,oCAAoC;EACpC,oCAAoC;EACpC,oCAAoC;EACpC,kCAAkC;EAClC,iCAAiC;EACjC,iCAAiC;EACjC,kCAAkC;EAClC,yCAAyC;EACzC,yCAAyC;EACzC,yCAAyC;EACzC,2CAA2C;AAC7C","sourcesContent":[".Theme_control_gpnDefault {\n  --control-radius: 8px; /* Основной радиус скругления контроллов */\n  --control-border-width: 1px; /* Основная толщина контроллов */\n  --control-height-l: var(--space-4xl);\n  --control-height-m: var(--space-3xl);\n  --control-height-s: var(--space-2xl);\n  --control-height-xs: var(--space-xl);\n  --control-box-size-s: var(--space-s);\n  --control-box-size-m: var(--space-m);\n  --control-box-size-l: var(--space-l);\n  --control-space-l: var(--space-xl);\n  --control-space-m: var(--space-l);\n  --control-space-s: var(--space-m);\n  --control-space-xs: var(--space-s);\n  --control-text-size-l: var(--size-text-l);\n  --control-text-size-m: var(--size-text-m);\n  --control-text-size-s: var(--size-text-s);\n  --control-text-size-xs: var(--size-text-xs);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import type { IAdmin } from 'src/types';
import http, { resetToken } from './http';
import storage from '../utils/storage';
import { PathENUM } from './constans';

const signIn = async (data: { email: string; password: string }) => {
  const response = await http.post<{user: IAdmin; tokens :{ accessToken: string; refreshToken: string }}>(`${PathENUM.AUTH}/admin`, data);

  storage.accessToken.set(response.data.tokens.accessToken);
  storage.refreshToken.set(response.data.tokens.refreshToken);
  resetToken();
  return response;
};

const getMe = () => {
  return http.get<IAdmin>(`${PathENUM.USERS}/me`);
};

export default {
  signIn,
  getMe,
};

import type { ThemePreset } from '@consta/uikit/Theme';
import '../_color/Theme_color_gpnDefault.css';
import '../_font/Theme_font_gpnDefault.css';
import '../_size/Theme_size_gpnDefault.css';
import '../_space/Theme_space_gpnDefault.css';
import '../_control/Theme_control_gpnDefault.css';

export const appPresetDefault: ThemePreset = {
  color: {
    primary: 'gpnDefault',
    accent: 'gpnDefault',
    invert: 'gpnDefault',
  },
  control: 'gpnDefault',
  font: 'gpnDefault',
  size: 'gpnDefault',
  space: 'gpnDefault',
  shadow: 'gpnDefault',
};

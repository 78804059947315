const safeJsonParse = <R = unknown>(string: string, fallbackValue?: R | null): R | null => {
  try {
    const parsed = JSON.parse(string);

    return parsed as R;
  } catch {
    return fallbackValue || null;
  }
};

const sleep = (timeout: number) => {
  return new Promise((res) => {
    setTimeout(res, timeout);
  });
};

const fileToBase64 = (file: File | Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};

const getBase64FromUrl = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const base64 = await fileToBase64(blob);

  return base64;
};

const getBase64File = (event: DragEvent | React.ChangeEvent<Element>) => {
  let file: File | null = null;

  if (event.type === 'dragend' || event.type === 'drop') {
    file = (event as DragEvent).dataTransfer?.files?.[0] || null;
  } else if (event.type === 'change') {
    file = (event.target as HTMLInputElement).files?.[0] || null;
  }
  return file;
};

const getBase64Files = (event: DragEvent | React.ChangeEvent<Element>) => {
  let files: FileList | null = null;

  if (event.type === 'dragend' || event.type === 'drop') {
    files = (event as DragEvent).dataTransfer?.files ?? null;
  } else if (event.type === 'change') {
    files = (event.target as HTMLInputElement).files ?? null;
  }
  return files;
};

const imageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

const getImagesFromFiles = (files: FileList) => {
  return Array.from(files).filter((file) => imageTypes.includes(file.type));
};

const deleteFileUrl = (url: string) => {
  URL.revokeObjectURL(url);
};

const getObjectKeys = <T extends object>(o: T): (keyof T)[] => {
  return Object.keys(o) as (keyof T)[];
};

const isObject = (A: unknown): A is Record<string, unknown> => {
  return typeof A === 'object' && !Array.isArray(A) && A !== null;
};

const deepEqual = (first: unknown, second: unknown) => {
  if (first === second) return true;

  if (Array.isArray(first) && Array.isArray(second)) {
    if (first.length !== second.length) return false;

    for (let i = 0; i < first.length; i++) {
      if (!deepEqual(first[i], second[i])) return false;
    }

    return true;
  }

  if (isObject(first) && isObject(second)) {
    const firstKeys = getObjectKeys(first);
    const secondKeys = getObjectKeys(second);

    if (firstKeys.length !== secondKeys.length) return false;

    for (let i = 0; i < firstKeys.length; i++) {
      const key = firstKeys[i];

      if (!deepEqual(first[key], second[key])) return false;
    }

    return true;
  }

  return false;
};

export default {
  safeJsonParse,
  sleep,
  fileToBase64,
  getBase64File,
  getBase64Files,
  getImagesFromFiles,
  deleteFileUrl,
  getBase64FromUrl,
  getObjectKeys,
  deepEqual,
};

import { createEntityAdapter } from '@reduxjs/toolkit';
import type { IBaseMessage, IChat, IParticipant } from 'src/types/chatType';

export const chatsEntityAdapter = createEntityAdapter({
  selectId: (conversation: IChat) => conversation.chatId,
});

export const participantsEntityAdapter = createEntityAdapter({
  selectId: (participant: IParticipant) => participant.participantId,
});

export const messagesEntityAdapter = createEntityAdapter({
  selectId: (message: IBaseMessage) => message.messageId,
});

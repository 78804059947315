import type { EntityState } from '@reduxjs/toolkit';
import type { IUser } from 'src/types';
import { usersEntityAdapter } from './entityAdapter';

export class UserStoreHelper {
  static updateRestrictions(entities: EntityState<IUser>, options: {
    userId: number;
    changes: {
      [K in keyof IUser['restrictions']]?: IUser['restrictions'][K];
    };
  }) {
    const user = entities.entities[options.userId];
    if (!user) {
      return;
    }
    usersEntityAdapter.updateOne(entities, {
      id: options.userId,
      changes: {
        restrictions: {
          ...user.restrictions,
          ...options.changes,
        } as IUser['restrictions'],
      },
    });
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --toast-y-position: 1.25rem;
  --toast-x-position: 2rem;
}

.Toastify__toast-container {
  width: auto;
  padding: 0;
}

.Toastify__toast-container--top-left {
  top: var(--toast-y-position);
  left: var(--toast-x-position);
}

.Toastify__toast-container--top-center {
  top: var(--toast-y-position);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: var(--toast-y-position);
  right: var(--toast-x-position);
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toast-y-position);
  left: var(--toast-x-position);
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toast-y-position);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toast-y-position);
  right: var(--toast-x-position);
}

.Toastify__toast {
  background: none;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  min-height: unset;
  overflow: unset;
  padding: 0;
}

.Toastify__toast-body {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles.toastify.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;EAC5B,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;EAC/B,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;EAC/B,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,+BAA+B;EAC/B,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[":root {\n  --toast-y-position: 1.25rem;\n  --toast-x-position: 2rem;\n}\n\n.Toastify__toast-container {\n  width: auto;\n  padding: 0;\n}\n\n.Toastify__toast-container--top-left {\n  top: var(--toast-y-position);\n  left: var(--toast-x-position);\n}\n\n.Toastify__toast-container--top-center {\n  top: var(--toast-y-position);\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.Toastify__toast-container--top-right {\n  top: var(--toast-y-position);\n  right: var(--toast-x-position);\n}\n\n.Toastify__toast-container--bottom-left {\n  bottom: var(--toast-y-position);\n  left: var(--toast-x-position);\n}\n\n.Toastify__toast-container--bottom-center {\n  bottom: var(--toast-y-position);\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.Toastify__toast-container--bottom-right {\n  bottom: var(--toast-y-position);\n  right: var(--toast-x-position);\n}\n\n.Toastify__toast {\n  background: none;\n  border-radius: 0;\n  box-shadow: none;\n  color: inherit;\n  min-height: unset;\n  overflow: unset;\n  padding: 0;\n}\n\n.Toastify__toast-body {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import { Text } from '@consta/uikit/Text';
import { IconExit } from '@consta/icons/IconExit';
import { clearLogout } from 'src/utils/auth/clearLogout';
import { Avatar } from '@consta/uikit/Avatar';
import Dropdown from '../../Dropdown/Dropdown';

import styles from './ProfilePreview.module.scss';

type PropsType = {
  name?: string;
  role?: string;
  userAvatar?: string | null;
};

const ProfilePreview: React.FC<PropsType> = (props) => {
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    clearLogout();
  };

  const dropdownItems = [
    {
      id: 1,
      fieldName: 'Logout',
      Icon: IconExit,
      onClick: handleLogout,
    },
  ];

  return (
    <button
      ref={ref}
      className={styles.container__profile_info}
      onClick={toggleDropdown}
    >
        <Avatar form="round" size="m" url={props?.userAvatar || ''} />
        <div className={styles.profile_info__name_and_role}>
          <Text
            className={styles.profile_info__name}
            view="system"
            size="m"
            lineHeight="xs"
            font="primary"
          >
            {props.name}
          </Text>
          <Text
            className={styles.profile_info__role}
            size="2xs"
            lineHeight="2xs"
            font="primary"
          >
            {props.role}
          </Text>
          <Dropdown
            setIsOpen={toggleDropdown}
            isOpen={isDropdownOpen}
            items={dropdownItems}
            ignoreClicksInsideRefs={[ref]}
          />
        </div>
    </button>
  );
};

export default ProfilePreview;

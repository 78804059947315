/* eslint-disable class-methods-use-this */
import type { Socket } from 'socket.io-client';
import storage from 'src/utils/storage';
import { clearLogout } from 'src/utils/auth/clearLogout';
import type { SocketEventsENUM } from './socketEvents';

export interface ISocketBaseControllersType {
  onConnect(socket: Socket): void;
  onDisconnect(): void;
  onError(arg: Error): void;
  onConnectError(arg: Error): void;
  getAuth(): Record<string, string>;
  handleForceLogout(): void;
}

class SocketBaseControllers implements ISocketBaseControllersType {
  private readonly SOCKET_EVENT_NAME = 'NEW_SOCKET_MESSAGE';

  onConnect(socket: Socket) {
    this.handleAnyEvent(socket);
  }

  onDisconnect() {
    this.unsubscribeFromAnyEvent?.();
  }

  getAuth(): Record<string, string> {
    const authToken = storage.accessToken.get() as string;

    return {
      token: authToken,
    };
  }

  onConnectError(arg: Error): void {
    console.error('Socket error:', arg);
  }

  onError(arg: Error): void {
    console.error('Socket error:', arg);
  }

  handleForceLogout(): void {
    clearLogout();
  }

  private unsubscribeFromAnyEvent?: () => void;

  private handleAnyEvent(socket: Socket) {
    const handleAnyEvent = (event: SocketEventsENUM, value: unknown) => {
      const socketEvent = new CustomEvent(this.SOCKET_EVENT_NAME, {
        detail: {
          event,
          value,
        },
      });

      window.dispatchEvent(socketEvent);
    };

    socket.onAny(handleAnyEvent);
    this.unsubscribeFromAnyEvent = () => {
      socket.offAny(handleAnyEvent);
    };
  }
}

export default SocketBaseControllers;

import type { FindAndCountMetaType, IOffer } from 'src/types';
import type { OffersByPageType } from './slice';

export class OfferDataHelper {
  static getDataPerPage(existingData: OffersByPageType | undefined, data: {
    offers: IOffer[];
    pagination: FindAndCountMetaType;
  }): OffersByPageType {
    const offersByPage = existingData || this.getDefault();
    offersByPage.items = data.offers.map(({ offerId }) => offerId);
    offersByPage.pagination = data.pagination;

    return offersByPage;
  }

  static getDataAsInfinity(existingData: OffersByPageType | undefined, data: {
    offers: IOffer[];
    pagination: FindAndCountMetaType;
  }): OffersByPageType {
    const offersDataToUpdate = existingData ||
      OfferDataHelper.getDefault();

    offersDataToUpdate.items =
      Array.from(new Set([
        ...offersDataToUpdate.items,
        ...data.offers.map(({ offerId }) => offerId),
      ]));
    offersDataToUpdate.pagination = data.pagination;

    return offersDataToUpdate;
  }

  static getDefault(): OffersByPageType {
    return {
      items: [],
      pagination: {
        page: 1,
        pageSize: 10,
        totalRecords: 0,
        totalPages: 0,
      },
    };
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'src/config';
import helpers from 'src/utils/helpers';
import BaseSocket from './BaseSocket';
import SocketBaseControllers from './SocketBaseControllers';
import type { SocketErrorResponseType, SocketResponseType } from '../http/http.types';
import type { SocketEventsENUM } from './socketEvents';

type InnerSocketResponseType<T_Payload, T_Meta extends object = Record<never, never>> = {
  error: null | SocketErrorResponseType;
  data: SocketResponseType<T_Payload, T_Meta>;
};

class MainSocket extends BaseSocket {
  constructor() {
    super(
      {
        uri: config.socketUrl,
        transports: ['websocket'],
        autoConnect: false,
        auth: {},
      },
      new SocketBaseControllers(),
    );
  }

  public async emit<T_Payload, T_Meta extends object = Record<never, never>>(
    event: string,
    params: unknown,
    requestCount = 1,
  ) {
    return new Promise<SocketResponseType<T_Payload, T_Meta>>((res, rej) => {
      this.socket
        .timeout(1000 * 60)
        .emit(
          event,
          params,
          async (err: Error, response: InnerSocketResponseType<T_Payload, T_Meta>) => {
            if (err) {
              if (err.message === 'operation has timed out') {
                if (requestCount < config.maxNumberOfAttempts) {
                  await helpers.sleep(1000);
                  await this.waitForConnection().catch(rej);
                  return this.emit<T_Payload, T_Meta>(event, params, requestCount + 1).then(
                    res,
                    rej,
                  );
                }
              }
              rej(err);
              return;
            }
            try {
              if (response.error) {
                return rej(response.error);
              }
              res(response.data);
            } catch (err) {
              console.error(
                `Failed to handle socket response ("${event}"):`,
                response,
                '\nError:',
                err,
              );
              rej(err);
            }
          },
        );
    });
  }

  public subscribe<T_Payload, T_Meta extends object = Record<string, never>>(
    event: SocketEventsENUM,
    cb: (data: SocketResponseType<T_Payload, T_Meta>) => void,
  ) {
    const innerCallback = ({ data }: { data: SocketResponseType<T_Payload, T_Meta> }): void => {
      cb(data);
    };

    this.socket.on(event, innerCallback);

    return () => {
      this.socket.off(event, innerCallback);
    };
  }
}

export const mainSocket = new MainSocket();

import { createSlice } from '@reduxjs/toolkit';
import type { EntityState, PayloadAction, Update } from '@reduxjs/toolkit';
import type {
  IParticipant,
  IChat,
  IChatResponse,
  IMessageResponse,
  IParticipantResponse,
  IReportResponse,
  ITempMessage,
  IBaseMessage,
} from 'src/types';
import { ReportStatusENUM } from 'src/types';
import helpers from 'src/utils/helpers';
import { chatThunks } from './thunks';
import {
  chatsEntityAdapter,
  messagesEntityAdapter,
  participantsEntityAdapter,
} from './entityAdapter';
import { ChatDataHelper } from './helpers/ChatDataHelper';
import { MessageDataHelper } from './helpers/MessageDataHelper';

type ChatStoreType = {
  selectedChatId: IChat['chatId'] | null;
  messages: EntityState<IBaseMessage>;
  participants: EntityState<IParticipant>;
  chats: EntityState<IChat>;
};

const chatSlice = createSlice({
  name: 'supportChat',
  initialState: {
    selectedChatId: null as IChat['chatId'] | null,
    messages: messagesEntityAdapter.getInitialState(),
    participants: participantsEntityAdapter.getInitialState(),
    chats: chatsEntityAdapter.getInitialState(),
  } satisfies ChatStoreType,
  reducers: {
    onNewMessage(store, action: PayloadAction<IMessageResponse>) {
      const transformedMessage = ChatDataHelper.TransformMessageFromResponse(action.payload);
      const chat = store.chats.entities[transformedMessage.chatId];

      messagesEntityAdapter.addOne(store.messages, transformedMessage);
      chatsEntityAdapter.updateOne(store.chats, {
        id: transformedMessage.chatId,
        changes: {
          lastMessage: transformedMessage,
          messagesIds: [...(chat?.messagesIds ?? []), transformedMessage.messageId],
        },
      });

      // add unread count to all participants except author message
      const toUpdate = (chat?.participantsIds ?? []).reduce((prev, id) => {
        if (id === transformedMessage.authorId) return prev;

        const unreadCounts = (store.participants.entities[id]?.unreadCounts ?? 0) + 1;

        prev.push({
          id,
          changes: {
            unreadCounts,
          },
        });

        return prev;
      }, [] as Update<IParticipant>[]);

      participantsEntityAdapter.updateMany(store.participants, toUpdate);
    },

    deleteTempMessage(
      store,
      action: PayloadAction<{
        messageId: ITempMessage['messageId'];
        chatId: IChat['chatId'];
      }>,
    ) {
      messagesEntityAdapter.removeOne(store.messages, action.payload.messageId);
      chatsEntityAdapter.updateOne(store.chats, {
        id: action.payload.chatId,
        changes: {
          messagesIds: (store.chats.entities[action.payload.chatId]?.messagesIds ?? []).filter(
            (id) => id !== action.payload.messageId,
          ),
        },
      });
    },

    onRequestStatusChange(
      store,
      action: PayloadAction<{
        chatId: number;
        report: IReportResponse;
        participant?: IParticipantResponse;
      }>,
    ) {
      const { chatId, report, participant } = action.payload;
      const transformedReport = ChatDataHelper.TransformReportFromResponse(report);
      const transformedParticipant = participant
        ? ChatDataHelper.TransformParticipantFromResponse(participant)
        : null;
      const currentChatParticipantsIds = store.chats.entities[chatId]?.participantsIds ?? [];

      if (transformedParticipant) {
        participantsEntityAdapter.addOne(store.participants, transformedParticipant);
      }

      chatsEntityAdapter.updateOne(store.chats, {
        id: chatId,
        changes: {
          participantsIds: transformedParticipant
            ? [...currentChatParticipantsIds, transformedParticipant.participantId]
            : currentChatParticipantsIds,
          report: transformedReport,
        },
      });
    },

    onIncomingNewRequest(store, action: PayloadAction<{ chat: IChatResponse }>) {
      const { chats, participants } = ChatDataHelper.TransformChatsFromResponse([
        action.payload.chat,
      ]);

      chatsEntityAdapter.addOne(store.chats, chats[0]);
      participantsEntityAdapter.addMany(store.participants, participants);
    },

    onUpdateParticipant(store, action: PayloadAction<{ participant: IParticipantResponse }>) {
      const transformedParticipant = ChatDataHelper.TransformParticipantFromResponse(
        action.payload.participant,
      );

      participantsEntityAdapter.setOne(store.participants, transformedParticipant);
    },

    selectChat(store, action: PayloadAction<{ chatId: IChat['chatId'] | null }>) {
      store.selectedChatId = action.payload.chatId;
    },
  },
  extraReducers: (buider) => {
    buider.addCase(chatThunks.getChatsThunk.fulfilled, (store, action) => {
      const { chats, participants } = ChatDataHelper.TransformChatsFromResponse(
        action.payload.chats,
      );

      const selectedChat = action.payload.selectedChat;
      participantsEntityAdapter.addMany(store.participants, participants);

      if (action.payload.rewriteChats) {
        chatsEntityAdapter.setAll(store.chats, chats);
      } else {
        chatsEntityAdapter.upsertMany(store.chats, chats);
      }
      messagesEntityAdapter.removeAll(store.messages);

      if (selectedChat) {
        chatsEntityAdapter.upsertOne(store.chats, selectedChat.chat);
        messagesEntityAdapter.addMany(store.messages, selectedChat.messages);
        participantsEntityAdapter.upsertMany(store.participants, selectedChat.participants);
      }
    });

    buider.addCase(chatThunks.getChatMessagesThunk.fulfilled, (store, action) => {
      const transformedMessages = ChatDataHelper.TransformMessagesFromResponse(
        action.payload.messages,
      );
      const chatId = action.meta.arg.chatId;

      messagesEntityAdapter.setAll(store.messages, transformedMessages);

      chatsEntityAdapter.updateOne(store.chats, {
        id: chatId,
        changes: {
          messagesIds: action.payload.messages.map((m) => m.messageId),
        },
      });
    });

    buider.addCase(chatThunks.createNewChatThunk.fulfilled, (store, action) => {
      const { chats, participants } = ChatDataHelper.TransformChatsFromResponse([
        action.payload.chat,
      ]);

      participantsEntityAdapter.addMany(store.participants, participants);
      chatsEntityAdapter.addOne(store.chats, chats[0]);
    });

    buider.addCase(chatThunks.chatReadMessagesThunk.fulfilled, (store, action) => {
      const transformedParticipant = ChatDataHelper.TransformParticipantFromResponse(
        action.payload.participant,
      );

      participantsEntityAdapter.updateOne(store.participants, {
        id: transformedParticipant.participantId,
        changes: {
          lastViewed: transformedParticipant.lastViewed,
          unreadCounts: transformedParticipant.unreadCounts,
        },
      });
    });

    buider.addCase(chatThunks.closeRequestThunk.fulfilled, (store, action) => {
      const transformedReport = ChatDataHelper.TransformReportFromResponse(action.payload);

      chatsEntityAdapter.updateOne(store.chats, {
        id: action.meta.arg.chatId,
        changes: {
          report: transformedReport,
        },
      });
    });

    buider.addCase(chatThunks.getRequestToWorkThunk.fulfilled, (store, action) => {
      const existingReport = store.chats.entities[action.meta.arg.chatId]?.report;

      if (!existingReport) {
        return;
      }

      existingReport.status = ReportStatusENUM.inProgress;
    });

    buider.addCase(chatThunks.createNewMessageThunk.pending, (store, action) => {
      const tempMessage = MessageDataHelper.createTempMessageEntity(
        action.meta.arg,
        action.meta.requestId,
      );

      messagesEntityAdapter.addOne(store.messages, tempMessage);

      chatsEntityAdapter.updateOne(store.chats, {
        id: action.meta.arg.chatId,
        changes: {
          messagesIds: [
            ...(store.chats.entities[action.meta.arg.chatId]?.messagesIds ?? []),
            action.meta.requestId,
          ],
        },
      });
    });

    buider.addCase(chatThunks.createNewMessageThunk.fulfilled, (store, action) => {
      const messageTransformed = ChatDataHelper.TransformMessageFromResponse(
        action.payload.message,
      );
      messagesEntityAdapter.removeOne(store.messages, action.meta.requestId);

      chatsEntityAdapter.updateOne(store.chats, {
        id: action.meta.arg.chatId,
        changes: {
          lastMessage: messageTransformed,
          messagesIds: ChatDataHelper.replaceTemporaryMessage(store.chats, {
            add: messageTransformed.messageId,
            remove: action.meta.requestId,
            id: action.meta.arg.chatId,
          }),
        },
      });

      messagesEntityAdapter.addOne(store.messages, messageTransformed);

      action.meta.arg.mediaItems.forEach((media) => {
        helpers.deleteFileUrl(media.url);
      });
    });

    buider.addCase(chatThunks.createNewMessageThunk.rejected, (store, action) => {
      messagesEntityAdapter.updateOne(store.messages, {
        id: action.meta.requestId,
        changes: {
          errored: true,
        },
      });
    });
  },
});

export const chatActions = chatSlice.actions;
export default chatSlice.reducer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Theme_space_gpnDefault {
  /* Отступы */
  --space-3xs: 2px;
  --space-2xs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 20px;
  --space-xl: 24px;
  --space-2xl: 32px;
  --space-3xl: 40px;
  --space-4xl: 48px;
  --space-5xl: 72px;
  --space-6xl: 96px;
}`, "",{"version":3,"sources":["webpack://./src/ui/defaultPreset/_space/Theme_space_gpnDefault.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".Theme_space_gpnDefault {\n  /* Отступы */\n  --space-3xs: 2px;\n  --space-2xs: 4px;\n  --space-xs: 8px;\n  --space-s: 12px;\n  --space-m: 16px;\n  --space-l: 20px;\n  --space-xl: 24px;\n  --space-2xl: 32px;\n  --space-3xl: 40px;\n  --space-4xl: 48px;\n  --space-5xl: 72px;\n  --space-6xl: 96px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import type { IActivity, IUser } from './index';

export enum CurrencyENUM {
  GBP = 'GBP',
  // USD = 'USD',
  // EUR = 'EUR',
}

export enum OfferStatusENUM {
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  EXPIRED = 'expired',
}

export interface IOffer {
  offerId: number;
  title: string;
  description: string | null;
  address: string;
  meetingPlace: string;
  price: number;

  currency: CurrencyENUM;
  status: OfferStatusENUM;

  createdAt: Date;
  updatedAt?: Date | null;
  deletedAt?: Date | null;

  startTime: string;
  endTime: string;
  isAccepted: boolean;
  isDone: boolean;
  activities?: IActivity[];

  author: IUser;
  customer: IUser;
}

import type { IRole } from '../../types';
import { PermissionsENUM } from './constants';

export const hasPermission = (adminRole: IRole) => {
  return adminRole.permissions.some(
    (permission) => permission.value === PermissionsENUM.FINANCE_MANAGEMENT,
  );
};

export const checkPermission = (
  adminRole: IRole,
  requiredPermissions: string[],
) => {
  return adminRole.permissions.some((permission) => requiredPermissions.includes(permission.value));
};

export const checkPermissionForChat = (adminRole: IRole) => {
  const isIncludesFinancePerms = adminRole.permissions.some(
    (permission) => permission.value === PermissionsENUM.FINANCE_MANAGEMENT,
  );
  if (isIncludesFinancePerms && adminRole.permissions.length === 1) {
    return false;
  }
  return true;
};

export const checkPermissinonForModerators = (adminRole: IRole) => {
  return adminRole.isSuperAdmin;
};

import store, { resetStore } from 'src/store/store';
import { mainSocket } from 'src/api/ws/MainSocket';
import storage from '../storage';

export const clearLogout = () => {
  mainSocket.disconnect();
  store.dispatch(resetStore());
  storage.accessToken.remove();
  storage.refreshToken.remove();
};

import Cookie from 'js-cookie';

import { isDev } from 'src/config';
import CoreStorageItem from './CoreStorageItem';

class CookieItem<D> extends CoreStorageItem<D> {
  constructor(params: {
    key: string;
    shouldAddPrefixToKey?: boolean;
    defaultValue?: D | null;
  }) {
    super({
      key: params.key,
      keyPrefix: 'prefix__',
      shouldAddPrefixToKey: params.shouldAddPrefixToKey ?? true,
      shouldUseEncoding: !isDev,
      defaultValue: params.defaultValue || null,
      getFunction: (key: string) => Cookie.get(key) || null,
      setFunction: Cookie.set,
      removeFunction: Cookie.remove,
    });
  }
}

export default CookieItem;

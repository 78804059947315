import React from 'react';
import cn from 'classnames';

import { Text } from '@consta/uikit/Text';
import type { IconComponent } from '@consta/icons/Icon';
import { useClickOutside } from '@consta/uikit/__internal__/src/hooks/useClickOutside';

import styles from './Dropdown.module.scss';

export type DropDownItemType = {
  id: number;
  fieldName: string;
  Icon?: IconComponent<'span'>;
  onClick?: () => void;
  status?: string;
};

type PropsType = {
  isOpen: boolean;
  setIsOpen: () => void;
  items: DropDownItemType[];
  ignoreClicksInsideRefs?: React.RefObject<HTMLElement>[];
};

const Dropdown: React.FC<PropsType> = (props) => {
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);

  useClickOutside({
    isActive: props.isOpen,
    ignoreClicksInsideRefs: [dropdownRef, ...(props.ignoreClicksInsideRefs ?? [])],
    handler: props.setIsOpen,
  });

  return (
    <div className={cn(styles.dropdown, { [styles.visible]: props.isOpen })} ref={dropdownRef}>
      {props.items.map(({ id, fieldName, Icon, onClick, status }) => (
        <div
          className={cn(styles.dropdown__item, {
            [styles.error]: status === 'error',
          })}
          key={id}
          onClick={onClick}
        >
          {Icon && <Icon className={styles.icon} />}
          <Text className={styles.text} lineHeight="xs" weight="medium">
            {fieldName}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default Dropdown;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationList_container__W5-LZ {
  position: absolute;
  top: 55px;
  right: 233px;
  opacity: 0;
  pointer-events: none;
}

.NotificationList_visible__s8umu {
  opacity: 1;
  transition: opacity 0.2s ease-out;
  display: block;
  pointer-events: auto;
  background-color: white;
  border-radius: 8px;
  padding: 10px 15px;
  width: 410px;
  height: 305px;
  overflow: scroll;
  box-shadow: -1px 3px 7px 0px rgba(207, 207, 207, 0.1019607843), -4px 12px 13px 0px rgba(207, 207, 207, 0.0901960784), -10px 27px 17px 0px rgba(207, 207, 207, 0.0509803922), -18px 48px 20px 0px rgba(207, 207, 207, 0.0117647059), -28px 75px 22px 0px rgba(207, 207, 207, 0);
  z-index: 9999;
}

.NotificationList_list__mAhvp {
  display: flex;
  flex-direction: column;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/ui/components/Notifications/NotificationList.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,UAAA;EACA,oBAAA;AACF;;AAEA;EACE,UAAA;EACA,iCAAA;EACA,cAAA;EACA,oBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,8QAAA;EAKA,aAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAHF","sourcesContent":[".container {\n  position: absolute;\n  top: 55px;\n  right: 233px;\n  opacity: 0;\n  pointer-events: none;\n}\n\n.visible {\n  opacity: 1;\n  transition: opacity 0.2s ease-out;\n  display: block;\n  pointer-events: auto;\n  background-color: white;\n  border-radius: 8px;\n  padding: 10px 15px;\n  width: 410px;\n  height: 305px;\n  overflow: scroll;\n  box-shadow: -1px 3px 7px 0px #CFCFCF1A,\n              -4px 12px 13px 0px #CFCFCF17,\n              -10px 27px 17px 0px #CFCFCF0D,\n              -18px 48px 20px 0px #CFCFCF03,\n              -28px 75px 22px 0px #CFCFCF00;\n  z-index: 9999;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotificationList_container__W5-LZ`,
	"visible": `NotificationList_visible__s8umu`,
	"list": `NotificationList_list__mAhvp`
};
export default ___CSS_LOADER_EXPORT___;

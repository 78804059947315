import React from 'react';
import cn from 'classnames';

import { Text } from '@consta/uikit/Text';
import { useClickOutside } from '@consta/uikit/__internal__/src/hooks/useClickOutside';

import globalStyles from 'src/ui/styles/global.module.scss';
import NotificationItem from './NotificationItem/NotificationItem';
import type { NotificationType } from './types';

import styles from './NotificationList.module.scss';

type PropsType = {
  notifications: NotificationType[];
  isOpen?: boolean;
  isDropdown?: boolean;
  ignoreClicksInsideRefs?: React.RefObject<HTMLElement>[];
  setIsOpen?: () => void;
};

const NotificationList: React.FC<PropsType> = (props) => {
  const listRef = React.useRef<HTMLDivElement | null>(null);
  useClickOutside({
    isActive: props.isOpen,
    ignoreClicksInsideRefs: [listRef, ...(props.ignoreClicksInsideRefs ?? [])],
    handler: props.setIsOpen,
  });

  React.useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    const alreadyContain = document.body.classList.contains(globalStyles['sidebar-open']);

    if (!alreadyContain) {
      document.body.classList.add(globalStyles['sidebar-open']);
    }
    return () => {
      if (!alreadyContain) {
        document.body.classList.remove(globalStyles['sidebar-open']);
      }
    };
  }, [props.isOpen]);

  return (
    <div
      ref={listRef}
      className={cn({
        [styles.container]: props.isDropdown,
        [styles.visible]: props.isOpen,
      })}
    >
      {props.isDropdown && (
        <Text size="s" weight="semibold">
          Notice
        </Text>
      )}
      <div
        className={cn({
          [styles.list]: !props.isDropdown,
        })}
      >
        {props.notifications.map((item) => (
          <NotificationItem key={item.label} {...item} />
        ))}
      </div>
    </div>
  );
};

export default NotificationList;

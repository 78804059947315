import { PathENUM } from './constans';
import http from './http';

const blockUser = (userId?: number) => {
  return http.post<void>(`${PathENUM.USERS}${PathENUM.PENALTY}/${userId}${PathENUM.BLOCK}`);
};
const banUser = (userId?: number) => {
  return http.post<void>(`${PathENUM.USERS}${PathENUM.PENALTY}/${userId}${PathENUM.BAN}`);
};
const unblockUser = (userId?: number) => {
  return http.post<void>(`${PathENUM.USERS}${PathENUM.PENALTY}/${userId}${PathENUM.UNBLOCK}`);
};
const unbanUser = (userId?: number) => {
  return http.post<void>(`${PathENUM.USERS}${PathENUM.PENALTY}/${userId}${PathENUM.UNBAN}`);
};

export default {
  blockUser,
  banUser,
  unblockUser,
  unbanUser,
};

import { useAppSelector } from 'src/store/store';

export const WithAuth = <P extends object>(Component: React.FC<P>) => {
  const WrappedComponent: React.FC<P> = (props: P) => {
    const admin = useAppSelector((state) => state.main.admin);
    if (!admin) {
      return null;
    }
    return <Component {...props} />;
  };

  return WrappedComponent;
};

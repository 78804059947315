import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Theme } from '@consta/uikit/Theme';
import { Loader } from '@consta/uikit/Loader';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import mainSliceThunks from 'src/store/mainSlice/mainSlice.thunks';
import { RouterProvider } from 'react-router-dom';
import { router } from './core/routes/Router';
import { appPresetDefault } from './ui/defaultPreset/presets/presetGpnDefault';

import styles from './ui/styles/global.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles.toastify.css';
import SocketInitialization from './ui/components/SocketInitialization/SocketInitialization';

const App: React.FC = () => {
  const isAuthChecked = useAppSelector(({ main }) => main.isAuthChecked);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(mainSliceThunks.authorize());
  }, [dispatch]);

  if (!isAuthChecked) {
    return <Loader />;
  }
  return (
    <Theme preset={appPresetDefault} className={styles.app}>
      <SocketInitialization />
      <ToastContainer closeButton={false} />
      <React.Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </React.Suspense>
    </Theme>
  );
};

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
}

#global_root__Ow7ZP {
  height: 100%;
  width: 100%;
  display: flex;
}

html,
body {
  height: 100%;
  width: 100%;
  display: flex;
}

.global_app__7Gigw {
  width: 100%;
}

.global_visibleBadge__FHPwU::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-base-alert);
  transition: opacity 0.3s ease;
}

.global_sidebar-open__RQ2xt {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/ui/styles/global.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,aAAA;AACF;;AACA;;EAEE,YAAA;EACA,WAAA;EACA,aAAA;AAEF;;AACA;EACE,WAAA;AAEF;;AAEE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yCAAA;EACA,6BAAA;AACJ;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n}\n\n#root {\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\nhtml,\nbody {\n  height: 100%;\n  width: 100%;\n  display: flex;\n}\n\n.app {\n  width: 100%;\n}\n\n.visibleBadge {\n  &::after {\n    content: '';\n    position: absolute;\n    top: 5px;\n    right: 5px;\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: var(--color-base-alert);\n    transition: opacity 0.3s ease;\n  }\n}\n\n.sidebar-open {\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `global_root__Ow7ZP`,
	"app": `global_app__7Gigw`,
	"visibleBadge": `global_visibleBadge__FHPwU`,
	"sidebar-open": `global_sidebar-open__RQ2xt`
};
export default ___CSS_LOADER_EXPORT___;

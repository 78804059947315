import React from 'react';
import { mainSocket } from 'src/api/ws/MainSocket';
import { WithAuth } from '../HOK/WithAuth';

const SocketInitialization: React.FC = () => {
  React.useEffect(() => {
    mainSocket.connect();
  }, []);

  return null;
};

export default WithAuth(SocketInitialization);

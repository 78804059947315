import { PathENUM } from 'src/api/constans';
import http from 'src/api/http';
import type { IMediaResponse } from 'src/types/mediaItemType';

const uploadFile = (data: { base64: string; name?: string }) => {
  return http.post<IMediaResponse>(`${PathENUM.MEDIA_ITEM}${PathENUM.UPLOAD}`, {
    file: data.base64,
    name: data.name,
  });
};

export default {
  uploadFile,
};

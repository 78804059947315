import type { EntityState } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FindAndCountMetaType, IOffer } from 'src/types';
import { OfferStatusENUM } from 'src/types/offerType';
import { offersEntityAdapter } from './entityAdapter';
import { offersThunks } from './thunks';
import { OfferDataHelper } from './helpers';

export type OffersByPageType = {
  items: number[];
  pagination: FindAndCountMetaType;
};
type OffersStateType = {
  offers: EntityState<IOffer>;

  offersByPage: {
    meets?: OffersByPageType;
    offers?: OffersByPageType;
  };

  offersByUserId: Record<
    number,
    {
      meets?: OffersByPageType;
      offers?: OffersByPageType;
    }
  >;
};

const initialState: OffersStateType = {
  offers: offersEntityAdapter.getInitialState(),
  offersByUserId: {},
  offersByPage: {
    meets: OfferDataHelper.getDefault(),
    offers: OfferDataHelper.getDefault(),
  },
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(offersThunks.getOffersByUserIdThunk.fulfilled, (store, action) => {
      offersEntityAdapter.upsertMany(store.offers, action.payload.offers);

      if (!action.meta.arg.infinity) {
        store.offersByPage.offers = OfferDataHelper.getDataPerPage(
          store.offersByPage.offers,
          action.payload,
        );
        return;
      }
      store.offersByUserId[action.meta.arg.id] = {
        ...(store.offersByUserId[action.meta.arg.id] || {}),
        offers: OfferDataHelper.getDataAsInfinity(
          store.offersByUserId[action.meta.arg.id]?.offers,
          action.payload,
        ),
      };
    });

    builder.addCase(offersThunks.getMeetingsByUserIdThunk.fulfilled, (store, action) => {
      offersEntityAdapter.upsertMany(store.offers, action.payload.offers);

      if (!action.meta.arg.infinity) {
        store.offersByPage.meets = OfferDataHelper.getDataPerPage(
          store.offersByPage.meets,
          action.payload,
        );
        return;
      }
      store.offersByUserId[action.meta.arg.id] = {
        ...(store.offersByUserId[action.meta.arg.id] || {}),
        meets: OfferDataHelper.getDataAsInfinity(
          store.offersByUserId[action.meta.arg.id]?.meets,
          action.payload,
        ),
      };
    });

    builder.addCase(offersThunks.getAllOffersThunk.fulfilled, (store, action) => {
      offersEntityAdapter.upsertMany(store.offers, action.payload.offers);
      store.offersByPage.meets = OfferDataHelper.getDataPerPage(
        store.offersByPage.meets,
        action.payload,
      );
    });

    builder.addCase(offersThunks.getOfferByIdThunk.fulfilled, (store, action) => {
      const offer = action.payload.offer;

      offersEntityAdapter.upsertOne(store.offers, offer);
    });

    builder.addCase(offersThunks.cancelOfferThunk.fulfilled, (store, action) => {
      offersEntityAdapter.updateOne(store.offers, {
        id: action.meta.arg.offerId,
        changes: {
          status: OfferStatusENUM.CANCELLED,
        },
      });
    });
  },
});

export const offersActions = offersSlice.actions;
export default offersSlice.reducer;

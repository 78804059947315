import React from 'react';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';

import type { ISidebarField } from '../sidebarFields';

import styles from './SidebarLink.module.scss';

const ACTIVE_COLOR = '#38BEDF';
const INACTIVE_COLOR = 'rgba(255, 255, 255, 0.75)';

type PropsType = {
  field: ISidebarField;
  isOpen: boolean;
};

const SidebarLink: React.FC<PropsType> = ({ field, isOpen }) => {
  const location = useLocation();
  const isActiveLink =
    location.pathname.includes(field.href) ||
    (field.childRoutes?.length &&
      field.childRoutes.includes(location.pathname));
  const Icon = field.Icon;
  return (
    <NavLink
      to={field.href}
      className={isActiveLink ? styles.link_selected : styles.link_unactive}
    >
      <div
        className={cn(styles.link__container, {
          [styles.visible]: isOpen,
        })}
      >
        <div className={styles.container__icon}>
          <div
            className={cn({
              [styles.invisible]: isOpen || !field.hasUnreadMessage,
              [styles.container__badge]: field.hasUnreadMessage && !isOpen,
            })}
          />
          <Icon fill={isActiveLink ? ACTIVE_COLOR : INACTIVE_COLOR} />
        </div>
        <Text
          as="p"
          className={cn(styles.container__text, {
            [styles.container__selected_text]: isActiveLink,
          })}
          size="m"
          font="primary"
          lineHeight="s"
        >
          {field.name}
        </Text>
      </div>
      {field.additionalInfo && (
        <Text as="p" className={styles.additional_info}>
          {field.additionalInfo}
        </Text>
      )}
    </NavLink>
  );
};

export default SidebarLink;

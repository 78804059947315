import { ReactComponent as MeetsPageLogo } from 'src/ui/assets/images/meetsPageLogo.svg';
import { ReactComponent as UsersPageLogo } from 'src/ui/assets/images/usersPageLogo.svg';
import { ReactComponent as SupportPageLogo } from 'src/ui/assets/images/supportPageLogo.svg';
import { routerConstants } from '../../../core/routes/constants';

export interface ISidebarField {
  name: string;
  href: string;
  childRoutes?: string[];
  additionalInfo?: string;
  hasUnreadMessage?: boolean;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

export const sidebarFields: ISidebarField[] = [
  {
    name: 'Meets',
    href: routerConstants.meets,
    Icon: MeetsPageLogo,
  },
  {
    name: 'Users',
    href: routerConstants.users,
    childRoutes: [routerConstants.moderators],
    Icon: UsersPageLogo,
  },
  {
    name: 'Support',
    href: routerConstants.support,
    Icon: SupportPageLogo,
  },
];

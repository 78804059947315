/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';
import cn from 'classnames';

import { Text } from '@consta/uikit/Text';
import { IconLeaf } from '@consta/icons/IconLeaf';

import styles from './Toast.module.scss';

type PropsType = {
  title: string;
  message: string;
  type: 'error' | 'success';
};

const Toast: React.FC<PropsType> = ({ title, message, type }) => {
  return (
    <div className={cn(styles.toast, {
      [styles.toast_error]: type === 'error',
      [styles.toast_success]: type === 'success',
    })}
    >
      <IconLeaf size="s" />
      <div>
        <Text size="m" weight="bold" lineHeight="s">
          {title}
        </Text>
        <Text size="m" lineHeight="s" style={{ whiteSpace: 'pre-line' }}>
          {message}
        </Text>
      </div>
    </div>
  );
};
export default Toast;

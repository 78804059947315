import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Button } from '@consta/uikit/Button';
import { IconSettings } from '@consta/icons/IconSettings';
import { IconRing } from '@consta/icons/IconRing';
import { ReactComponent as Bfriendz } from 'src/ui/assets/images/Bfriendz.svg';
import { ReactComponent as BFLogo } from 'src/ui/assets/images/BFLogo.svg';

import type { NotificationType } from '../Notifications/types';
import NotificationList from '../Notifications/NotificationList';
import { routerConstants } from '../../../core/routes/constants';

import styles from './Topbar.module.scss';
import global from '../../styles/global.module.scss';
import ProfilePreview from './ProfilePreview/ProfilePreview';

type PropsType = {
  notifications?: NotificationType[];
  name?: string;
  role: string;
  userAvatar?: string | null;
};

const Topbar: React.FC<PropsType> = (props) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <div className={styles.topbar} ref={ref}>
      <div className={styles.topbar__logo}>
        <BFLogo />
        <Bfriendz />
      </div>
      <div className={styles.topbar__container}>
        <Button
          className={cn(styles.container__button_notification, {
            [global.visibleBadge]: props.notifications?.length,
          })}
          iconRight={IconRing}
          onlyIcon
          view="clear"
          onClick={toggleNotifications}
        />
        <Link
          to={routerConstants.profile}
          className={styles.container__button_settings}
         >
          <IconSettings />
        </Link>
        <NotificationList
          isDropdown
          ignoreClicksInsideRefs={[ref]}
          notifications={props.notifications || []}
          isOpen={isNotificationsOpen}
          setIsOpen={toggleNotifications}
        />
        <ProfilePreview
          name={props.name}
          role={props.role}
          userAvatar={props.userAvatar}
        />
      </div>
    </div>
  );
};

export default Topbar;
